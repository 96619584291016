import React, { useEffect, useState, useCallback, useRef } from "react";

import useOutsideClick from "../../hooks/useOutsideClick";
import { useMessage } from "../../context/MessageContext";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";
import { getApi } from "../../apis/api";

import { XCrossIcon } from "../../assets";

import "../../assets/scss/abstracts/_form.scss";

interface MutliDropdownType {
  columns: ColumnTypes[];
  captionTitles?: string;
  data: any[];
  setData: (data: any) => void;
  rowData: any;
  setRowData: (row: any) => void;
  displayColumn: string;
  agentId?: string;
  type?: string;
  apiUrl: string;
  limit?: number;
  setAgentRowData?: (data: any) => void;
  agentFind?: boolean;
  disable?: boolean;
  enableRemove?: boolean;
  labelTitle?: string;
  mandatory?: boolean;
  checkInputChange?: () => void;
  parentCustomerId?: string;
  skipApiCallWhenNoParent?: boolean; // control API call based on parentCustomerId
  extendedUrl?: Record<string, string>; // Additional key-value pairs to append to the API URL
  status?: number;
  extraParentId?: {
    key: string;
    id: string;
  };
}

interface ColumnTypes {
  key: string;
  title: string;
}

const MutliDropdown: React.FC<MutliDropdownType> = ({
  columns,
  captionTitles = "Select",
  data,
  setData,
  rowData,
  setRowData,
  displayColumn,
  agentId,
  type,
  apiUrl,
  limit = 15,
  setAgentRowData,
  agentFind = false,
  disable,
  enableRemove,
  labelTitle,
  mandatory,
  checkInputChange,
  parentCustomerId,
  skipApiCallWhenNoParent,
  extendedUrl,
  status = 1,
  extraParentId,
}) => {
  const { setMessage } = useMessage();
  const [isSelected, setIsSelected] = useState<number | null>(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
  const [isDivFocused, setIsDivFocused] = useState(false);
  const isFetching = useRef(false);

  /**
   * Toggles the dropdown open/close state.
   */
  const handleOpened = () => {
    if (disable) {
      return;
    }
    setIsOpened(!isOpened);
    setIsSearchVisible(!isSearchVisible);
  };

  /**
   * Opens the dropdown and enables the search field.
   */
  const handleDoubleClick = () => {
    if (disable) {
      return;
    }
    setIsOpened(true);
    setIsSearchVisible(true);
  };

  /**
   * Custom hook to handle clicks outside the dropdown, closing it.
   */
  const outsideClickRef = useOutsideClick<HTMLDivElement>(() => {
    setIsOpened(false);
    setIsSearchVisible(false);
    setIsDivFocused(false);
  });

  /**
   * Handles keyboard navigation and selection within the dropdown.
   *
   * @param {React.KeyboardEvent} e - The keyboard event.
   */

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpened) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if (isSelected === null || isSelected === data.length - 1) {
          setIsSelected(0);
        } else {
          setIsSelected((prev) => (prev! + 1) % data.length);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (isSelected === null || isSelected === 0) {
          setIsSelected(data.length - 1);
        } else {
          setIsSelected((prev) => (prev! - 1 + data.length) % data.length);
        }
        break;
      case "Enter":
        if (isSelected !== null) {
          setRowData(data[isSelected]);
          setIsOpened(false);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Handles search input changes and resets pagination.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    setPage(1);
    setHasMore(true);
  }, []);

  /**
   * Handles removing the selected row data.
   */
  const handleRemoveRowData = () => {
    setRowData(null);
    setIsSelected(null);
    setIsOpened(false);
  };

  /**
   * Ensures the selected item is scrolled into view when navigating with the keyboard.
   */
  useEffect(() => {
    if (isSelected !== null && isOpened) {
      const selectedItem = document.querySelector(".selected");
      if (selectedItem) {
        selectedItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [isSelected, isOpened]);

  /**
   * Converts the extendedUrl object into a query string.
   */
  const getExtendedUrlParams = () => {
    if (!extendedUrl) return "";
    return Object.entries(extendedUrl)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
  };

  /**
   * Fetches filtered data from the API based on the search keyword and other parameters.
   *
   * @param {boolean} reset - Whether to reset the current data or append new data.
   */
  const fetchFilteredData = useCallback(
    async (reset: boolean = false) => {
      if (
        isFetching.current ||
        !apiUrl ||
        isLoading ||
        (skipApiCallWhenNoParent && !parentCustomerId)
      ) {
        return;
      }
      isFetching.current = true; // Prevent multiple calls
      try {
        setIsLoading(true);
        let url = `${apiUrl}?limit=${limit}&page=${reset ? 1 : page}`;
        if (keyword) {
          url += `&keyword=${keyword}`;
        }
        if (agentId) {
          url += `&agentId=${agentId}`;
        }
        if (type) {
          url += `&type=${type}`;
        }
        if (parentCustomerId) {
          url += `&parentCustomerId=${parentCustomerId}`;
        }
        if (extraParentId?.id) {
          url += `&${extraParentId.key}=${extraParentId.id}`;
        }

        if ([1, 0]?.includes(status)) {
          url += `&status=${status}`;
        }
        // Append the extended URL parameters if they exist
        const extendedUrlParams = getExtendedUrlParams();
        if (extendedUrlParams) {
          url += `&${extendedUrlParams}`;
        }

        const response = await getApi(url);

        if (response.success) {
          const userData: any = response.data || [];

          const mappedData = userData.length
            ? userData.map((item: any) =>
                columns.reduce(
                  (acc, column) => {
                    acc[column.key] = item[column.key];
                    if (column.key === "agentType") {
                      acc["agentType"] = item[column.key]?.name;
                    }
                    return acc;
                  },
                  { id: item.id, customerLevel: item?.customerLevel } as any
                )
              )
            : userData?.addedByTenants?.map((item: any) =>
                columns.reduce(
                  (acc, column) => {
                    acc[column.key] = item[column.key];
                    return acc;
                  },
                  {
                    id: item.customerId,
                    customerLevel: item?.customerLevel,
                  } as any
                )
              );
          if (agentFind) {
            const agent = userData.find((item: any) => item.id === agentId);
            setAgentRowData && setAgentRowData(agent);
          }
          setData(reset ? mappedData : [...data, ...mappedData]);

          if (response?.pagination?.totalPages) {
            const checkPage = reset ? 1 : page;
            setHasMore(checkPage < response?.pagination?.totalPages);
          } else {
            setHasMore(userData.length >= limit);
          }
        } else {
          setMessage(response.error?.message || GENERAL_ERROR_MESSAGE, "error");
        }
      } catch (error) {
        setMessage(GENERAL_ERROR_MESSAGE, "error");
      } finally {
        setIsLoading(false);
         isFetching.current = false; // Reset flag after fetch
      }
    },
    [
      apiUrl,
      keyword,
      page,
      limit,
      columns,
      setData,
      data,
      setMessage,
      agentId,
      setAgentRowData,
      isLoading,
      parentCustomerId,
      extraParentId?.id,
    ]
  );

  /**
   * Effect to trigger data fetching when the keyword changes, with a debounce mechanism.
   */
  useEffect(() => {
    if (keyword) {
      const debounceFetch = setTimeout(() => {
        fetchFilteredData(true);
      }, 1000);
      return () => clearTimeout(debounceFetch);
    } else {
      fetchFilteredData(true);
    }
  }, [apiUrl, keyword]);

  /**
   * Effect to load more data when scrolling reaches the bottom.
   */
  useEffect(() => {
    const handleScroll = () => {
      if (dropdownRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
        if (
          scrollTop + clientHeight >= scrollHeight - 10 &&
          !isLoading &&
          hasMore
        ) {
          setPage((prev) => prev + 1);
        }
      }
    };

    const currentRef = dropdownRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading, hasMore]);

  /**
   * Effect to fetch data when the page number changes.
   */
  useEffect(() => {
    if (page > 1) {
      fetchFilteredData();
    }
  }, [page]);

  /**
   * Effect to fetch data when the parentCustomerId changes.
   */
  useEffect(() => {
    fetchFilteredData(true);
    if (parentCustomerId || extraParentId) {
      handleRemoveRowData();
    }
    // handleRemoveRowData();
  }, [parentCustomerId, extraParentId?.id]);

  useEffect(() => {
    if (rowData) {
      if (
        rowData.location &&
        isSelected != null &&
        (data[isSelected].location != rowData.location ||
          data[isSelected].Location != rowData.Location)
      ) {
        setIsSelected(
          data.findIndex((item) => item.location === rowData.location)
        );
      }
    }
  }, [rowData]);

  const handleFocus = () => setIsDivFocused(true);
  const handleBlur = () => setIsDivFocused(false);

  return (
    <>
      <div className="form__Field">
        {labelTitle && (
          <label className="form__label">
            {labelTitle}{" "}
            {mandatory && <span className="mandatory-mark">*</span>}
          </label>
        )}
        <div
          tabIndex={0}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`customSelect ${isOpened ? "open" : ""}`}
          style={(isDivFocused && !disable)?{border: "solid 1px"}:{border: "0px"}}
          ref={outsideClickRef}
          onKeyDown={handleKeyDown}
        >
          {isSearchVisible ? (
            <input
              type="text"
              placeholder="Search..."
              value={keyword}
              onChange={handleSearch}
              className="form__input form__input--search form__input--sm"
              autoFocus
            />
          ) : (
            <div
              className={`customSelect__caption ${
                disable ? "customSelect__caption--disabled" : ""
              }`}
              onClick={handleOpened}
              //onDoubleClick={handleDoubleClick}
            >
              {rowData?.[displayColumn] ? (
                rowData?.[displayColumn]
              ) : (
                <span className="customSelect__caption__placeholder">
                  {captionTitles}
                </span>
              )}

              {rowData?.[displayColumn] && enableRemove && !disable && (
                <span
                  style={{
                    background: "#fff",
                    position: "absolute",
                    right: "8px",
                    top: "10px",
                  }}
                  onClick={() => {
                    handleRemoveRowData();
                  }}
                >
                  <XCrossIcon />
                </span>
              )}
            </div>
          )}
          <div
            className={`customSelect__dropdown ${
              columns.length === 3 ? "customSelect__dropdown--width450" : ""
            }`}
            ref={dropdownRef}
          >
            <div className="customSelect__dropdown__header">
              {columns.map((column) => (
                <div
                  className={`col ${columns.length === 3 ? "col--col3" : ""}`}
                  key={column.key}
                >
                  <h4 className="title">{column.title}</h4>
                </div>
              ))}
            </div>
            <div className="customSelect__dropdown__body">
              {isLoading && page === 1 ? (
                <div className="customSelect__dropdown__noitem">
                  <div className="col">Loading...</div>
                </div>
              ) : !data || data?.length === 0 ? (
                <div className="customSelect__dropdown__noitem">
                  <div className="col">No data found.</div>
                </div>
              ) : (
                data.map((item, index) => (
                  <div
                    className={`customSelect__dropdown__item ${
                      item?.id === rowData?.id && "selected"
                    }`}
                    onClick={() => {
                      setRowData(item);
                      setIsSelected(index);
                      handleOpened();
                      checkInputChange && checkInputChange();
                      setKeyword("");
                    }}
                    key={index}
                  >
                    {columns.map((column) => (
                      <div
                        key={column.key}
                        className={`col ${
                          columns.length === 3 ? "col--col3" : ""
                        }`}
                      >
                        {item[column.key]}
                      </div>
                    ))}
                  </div>
                ))
              )}
              {isLoading && page > 1 && (
                <div className="customSelect__dropdown__noitem">
                  <div className="col">Loading more...</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MutliDropdown;
