import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Table, FilterModal } from "../../../components/shared";
import useSearchAndFilter from "../../../hooks/useSearchAndFilter";
import { Loader } from "../../../components/loaders/loaders";
import SearchInput from "../../../components/shared/SearchInput";
import {
  ApiResponse,
  xcelaratorColumns,
  xcelaratorTableConfig,
} from "../XcelaratorApi/xcelaratorTypes";

import { AddIcon, FilterIcon } from "../../../assets";
import { agentApiColumns } from "./AgentApi-types";

/**
 * The `XcelaratorApi` component is responsible for displaying and managing
 * a table of Xcelarator API data. It includes search and filter functionality,
 * as well as options to create new entries, edit, delete, and view profiles.
 */
const AgentApi: React.FC = () => {
  const navigate = useNavigate();

  /**
   * Maps raw API response data to a format suitable for the table component.
   *
   * @param {ApiResponse[]} userData - Array of user data from the API.
   * @returns - Mapped array of user data.
   */
  const mapUserData = (userData: ApiResponse[]) =>
    userData.map((user) => ({
      id: user.id,
      count: user.count,
      agentName: user?.agent?.name || "",
      agentId: user?.agent?.id || "",
      clientCode: user?.clientCode || "",
      hubName: user?.agentHub?.name || "",
      clientApiKey: user?.clientAPI || "--",
      apiType: user?.apiType || "",
      active: user?.active ?? false,
      //isSignedUp: user?.isSignedUp ?? true,
      logo: user?.logo ?? null,
    }));

  // Custom hook to handle search, filter, and fetch data functionality
  const {
    data,
    keyword,
    loading,
    setLoading,
    handleKeywordChange,
    fetchFilteredData,
    closeOpenfilterModal,
    isShowFilterModal,
    selectStatus,
    setSelectStatus,
    selectApiType,
    setSelectApiType,
  } = useSearchAndFilter<ApiResponse>({
    apiUrl: "/agent/clientCredential/agentcredlist",
    limit: 15,
    mapUserData,
    extentedUrl: "",
  });

  /**
   * Handles navigation to a specified URL.
   *
   * @param {string} url - The URL to navigate to.
   */
  const handleNavigateUser = (url: string) => {
    navigate(url);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="tableWrap">
      <div className="tableWrap__head">
        <div className="formRow formRow__3 m_0 w_100 formRow--flexEnd">
          <div className="formCol">
            <SearchInput
              type="text"
              value={keyword}
              placeholder="Search a Name"
              onChange={handleKeywordChange}
            />
          </div>
          <div className="formCol">
            <button className="btn btn__white" onClick={closeOpenfilterModal}>
              <FilterIcon />
              Filter
            </button>
            <FilterModal
              isShowFilterModal={isShowFilterModal}
              closeOpenfilterModal={closeOpenfilterModal}
              selectStatus={selectStatus}
              setSelectStatus={setSelectStatus}
              isHubHide={true}
              isOrderTypeHide={true}
              isOrderStatusHide={true}
              isRoleHide={true}
              isServiceHide={true}
              fetchFilteredData={fetchFilteredData}
              isAgentTypeHide={true}
              isApiTypeShow={true}
              selectApiType={selectApiType}
              setSelectApiType={setSelectApiType}
            />
          </div>
        </div>
        <div className="btn__group">
          <button
            className="btn btn__white"
            onClick={() => {
              handleNavigateUser("/agent-api/add");
            }}
          >
            <AddIcon />
            Create New
          </button>
        </div>
      </div>
      <Table
        columns={agentApiColumns}
        data={data}
        setLoading={setLoading}
        onEditNaviagte={xcelaratorTableConfig.edit}
        onDeleteConfig={xcelaratorTableConfig.delete}
        //onActiveConfig={xcelaratorTableConfig.active}
        profileLinkNaviagte={xcelaratorTableConfig.profileLink}
        getApiData={fetchFilteredData}
        deleteIcon={true}
        editIcon={true}
        activeIcon={true}
        //manageIcon={true}
      />
    </div>
  );
};

export default AgentApi;
