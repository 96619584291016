import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import useSearchAndFilter from "../../../hooks/useSearchAndFilter";
import { Pagination, SearchInput, Table } from "../../../components/shared";
import { Loader } from "../../../components/loaders/loaders";
import {
  ServiceTypeResponseType,
  ServiceTypeTableConfig,
} from "./Services-types";
import { AddIcon } from "../../../assets";
import ServicesModal from "../../../components/modals/systemConfigurationModal/AddServiceModal";
import { GET_SERVICES } from "../../../apis/end-points";

/**
 * Maps user data from the API response to the format required by the table.
 *
 * @param {Users[]} userData - Array of user objects.
 * @returns {object[]} - Mapped user data.
 */
const mapUserData = (data: ServiceTypeResponseType[]): object[] =>
  data.map((item) => ({
    id: item?.id || "",
    count: item?.count || "",
    serviceName: item?.name || "",
    description: item?.description || "",
    shortName: item?.shortName || "",
    agentType: item?.agentType?.name || "",
    agentTypeId: item?.agentType?.id || "",
    deliverWithin: item?.deliverWithin || "",
    comment: item?.comment || "",
    dispatchColor: item?.dispatchTicketColor || "#bb0707",
    dispatchPrinter: item?.dispatchPrinter || "",
    useOver150Lbs: item?.useOver150Lbs || false, // Boolean values should default to `false`
    canCustomerUse: item?.canCustomerUse || false, // Boolean values should default to `false`
    active: item?.isActive || false,
    longName: item?.longName || "",
    vehicleType: item?.vehicleType || "",
    serviceDays: item?.serviceDays || [],
    minsNotReceiveMsg: item?.minsNotReceiveMsg || "",
    minsNotReadMsg: item?.minsNotReadMsg || "",
    zoneSet: item?.zoneSet || "",
    delSignRequired: item?.delSignRequired || false,
    printDispatchTrack: item?.printDispatchTrack || false,
    orderDispatch: item?.orderDispatch || false,
    notReceivedMsgAlert: item?.notReceivedMsgAlert || false,
    notReadMsgAlert: item?.notReadMsgAlert || false,
    skipPickupStops: item?.skipPickupStops || false,
    skipDelRoundStops: item?.skipDelRoundStops || false,
    discInvoiceLevel: item?.disInvoiceLevel || false,
    availCust: item?.availCust || false,
  }));

// );

const Services: React.FC = () => {
  const navigate = useNavigate();
  const [openAddServiceModal, setOpenAddServiceModal] =
    useState<boolean>(false);
  const [rowData, setRowData] = useState<any>();

  const {
    data,
    loading,
    setLoading,
    fetchFilteredData,
    refreshPage,
    keyword,
    handleKeywordChange,
  } = useSearchAndFilter<ServiceTypeResponseType>({
    apiUrl: GET_SERVICES,
    limit: 15,
    mapUserData,
  });

  const handleEditModal = (data: any) => {
    setOpenAddServiceModal(true);
    setRowData(data);
  };

  return (
    <>
      <div className="tableWrap">
        <div className="tableWrap__head">
          <div className="formRow formRow__3 m_0 w_100 formRow--flexEnd">
            <div className="formCol">
              <SearchInput
                type="text"
                value={keyword}
                placeholder="Search a service"
                onChange={handleKeywordChange}
              />
            </div>
          </div>
          <button
            className="btn btn__white w_fitContent"
            onClick={() => {
              setOpenAddServiceModal(true);
            }}
          >
            <AddIcon />
            Create New
          </button>
        </div>
        <Table
          columns={ServiceTypeTableConfig.columns}
          data={data}
          setLoading={setLoading}
          editModalHanlde={handleEditModal}
          onDeleteConfig={ServiceTypeTableConfig.delete}
          onActiveConfig={ServiceTypeTableConfig.active}
          getApiData={fetchFilteredData}
          deleteIcon={true}
          activeIcon={true}
          editIcon={true}
        />
      </div>
      {openAddServiceModal && (
        <ServicesModal
          handleClose={() => {
            setOpenAddServiceModal(false);
            setRowData(null);
          }}
          setLoading={setLoading}
          refreshList={() => refreshPage()}
          data={rowData}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default Services;
