import React, { useEffect, useMemo, useState } from "react";

import { useMessage } from "../../../context/MessageContext";
import { getApi, postApi, putApi } from "../../../apis/api";
import { GENERAL_ERROR_MESSAGE } from "../../../utils/message";

import { XCrossIcon } from "../../../assets";
import { MutliDropdown } from "../../shared";
import {
  ADD_SERVICES,
  GET_AGENT_TYPE,
  UPDATE_SERVICES,
} from "../../../apis/end-points";

interface AddSerivesModalType {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  refreshList?: () => void;
  data?: any;
}
interface ColumnTypes {
  key: string;
  title: string;
}
const vehicleTypeColumns: ColumnTypes[] = [
  { key: "name", title: "Vehicle Type" },
  //   { key: "vehicleType", title: "Vehicle Type" },
];
interface VehicleRowDataType {
  id?: string;
  name: string | null;
  vehicleType?: "";
}

interface ServicesFields {
  id?: string;
  serviceName: string;
  vehicleType: string;
  shortName: string;
  longName: string;
  deliverWithin: string;
  dispatchTicketColor: string;
  dispatchPrinter: string;
  agentType: string;
  useOver150Lbs: boolean;
  description: string;
  serviceDays: string[];
  minsNotReceiveMsg: string;
  minsNotReadMsg: string;
  zoneSet: string;
  delSignRequired: boolean;
  printDispatchTick: boolean;
  orderDispatch: boolean;
  notReceivedMsgAlert: boolean;
  notReadMsgAlert: boolean;
  skipPickupStops: boolean;
  skipDelRoundStops: boolean;
  availCust: boolean;
  discInvoiceLevel: boolean;
  isActive: boolean;
  comment: string;
}

const ServicesModal: React.FC<AddSerivesModalType> = ({
  handleClose,
  setLoading,
  refreshList,
  data,
}) => {
  const { setMessage } = useMessage();
  const [formData, setFormData] = useState<ServicesFields>({
    id: "",
    serviceName: "",
    vehicleType: "",
    shortName: "",
    longName: "",
    deliverWithin: "",
    dispatchTicketColor: "#bb0707",
    dispatchPrinter: "",
    agentType: "",
    useOver150Lbs: true,
    description: "",
    serviceDays: [],
    minsNotReceiveMsg: "",
    minsNotReadMsg: "",
    zoneSet: "",
    delSignRequired: false,
    printDispatchTick: false,
    orderDispatch: false,
    notReceivedMsgAlert: false,
    notReadMsgAlert: false,
    skipPickupStops: false,
    skipDelRoundStops: false,
    availCust: false,
    discInvoiceLevel: false,
    isActive: true,
    comment: "",
  });
  const [errors, setErrors] = useState<any>({
    deliverWithin: "",
    minsNotReceiveMsg: "",
    minsNotReadMsg: "",
    zoneSet: "",
  });
  const [vehicleTypeDropdown, setVehicleTypeDropdown] = useState<
    VehicleRowDataType[]
  >([]);
  const [agentType, setAgentType] = useState<string[]>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isDivFocused, setIsDivFocused] = useState(false);
  const [isTypeFocused, setIsTypeFocused] = useState(false);
  const [vehicleRowData, setVehicleRowData] = useState<VehicleRowDataType>({
    id: "",
    name: "",
    vehicleType: "",
  });

  const handleModalClose = () => {
    handleClose();
  };

  const getAgentTypes = async () => {
    const apiResponse = await getApi<any>(
      GET_AGENT_TYPE + "?type=dropdown" + "&sort=name"
    );
    if (apiResponse.success) {
      setAgentType(apiResponse.data);
    }
  };
  useEffect(() => {
    getAgentTypes();
  }, []);
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      vehicleType: vehicleRowData?.id ?? "",
    }));
  }, [vehicleRowData?.id]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const apiPayload = {
        ...formData,
        disInvoiceLevel: formData.discInvoiceLevel,
        name: formData.serviceName,
        printDispatchTrack: formData.printDispatchTick,
      };
      let apiResponse;
      if (formData?.id) {
        delete apiPayload?.id;
        apiResponse = await putApi(
          `${UPDATE_SERVICES}${formData?.id}`,
          apiPayload
        );
      } else {
        delete apiPayload?.id;
        apiResponse = await postApi(ADD_SERVICES, apiPayload);
      }
      if (apiResponse.success) {
        if (formData?.id) {
          setMessage("Service updated successfully!", "success");
        } else {
          setMessage("Service saved successfully!", "success");
        }
        refreshList && refreshList();
        handleClose();
      } else {
        setMessage(
          apiResponse.error?.message ?? GENERAL_ERROR_MESSAGE,
          "error"
        );
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { id, value, checked, type } = e.target;
    if (
      id === "deliverWithin" ||
      id === "minsNotReceiveMsg" ||
      id === "minsNotReadMsg" ||
      id === "zoneSet"
    ) {
      if (value !== "" && !/^\d+$/.test(value)) {
        setErrors((prev: any) => ({
          ...prev,
          [id]: "please enter a numeric value",
        }));
      } else {
        setErrors((prev: any) => ({
          ...prev,
          [id]: "",
        }));
      }
      value = value.replace(/\D/g, "");
    }
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value.trimStart(),
    }));
    setHasChanges(true);
  };
  const handleAgentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      agentType: value,
    }));
    setHasChanges(true);
  };

  const handleWeekDayChanges = (day: string) => {
    setFormData((prev) => {
      return {
        ...prev,
        serviceDays: prev.serviceDays.includes(day)
          ? prev.serviceDays.filter((d) => d !== day)
          : [...prev.serviceDays, day],
      };
    });
    setHasChanges(true);
  };

  const isButtonDisabled = useMemo(() => {
    let areFieldsComplete = false;
    if (
      !formData?.vehicleType ||
      !formData?.agentType ||
      !formData?.serviceName ||
      !formData?.deliverWithin ||
      !formData?.description ||
      !hasChanges
    ) {
      areFieldsComplete = true;
    }

    return areFieldsComplete;
  }, [formData]);

  useEffect(() => {
    if (data?.id) {
      setVehicleRowData({
        id: data?.vehicleType?.id,
        name: data?.vehicleType?.name,
      });
      setFormData((prev: ServicesFields) => ({
        ...prev,
        id: data?.id || "",
        serviceName: data?.serviceName || "",
        vehicleType: data?.vehicleType?.id || "",
        shortName: data?.shortName || "",
        longName: data?.longName || "",
        description: data?.description || "",
        dispatchTicketColor: data?.dispatchColor || "#bb0707",
        dispatchPrinter: data?.dispatchPrinter || "",
        agentType: data?.agentTypeId || "",
        useOver150Lbs: data?.useOver150Lbs || false,
        serviceDays: data?.serviceDays || [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
        ],
        minsNotReceiveMsg: data?.minsNotReceiveMsg || "",
        minsNotReadMsg: data?.minsNotReadMsg || "",
        deliverWithin: data?.deliverWithin || "",
        zoneSet: data?.zoneSet || "",
        delSignRequired: data?.delSignRequired || false,
        printDispatchTick: data?.printDispatchTrack || false,
        notReceivedMsgAlert: data?.notReceivedMsgAlert || false,
        notReadMsgAlert: data?.notReadMsgAlert || false,
        skipPickupStops: data.skipPickupStops,
        skipDelRoundStops: data.skipDelRoundStops,
        availCust: data.availCust,
        orderDispatch: data.orderDispatch,
        discInvoiceLevel: data?.discInvoiceLevel,
        comment: data?.comment,
        isActive: data?.active,
      }));
    } else {
      setFormData({
        id: "",
        serviceName: "",
        vehicleType: "",
        shortName: "",
        longName: "",
        deliverWithin: "",
        dispatchTicketColor: "#bb0707",
        dispatchPrinter: "",
        agentType: "",
        useOver150Lbs: true,
        description: "",
        serviceDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        minsNotReceiveMsg: "",
        minsNotReadMsg: "",
        zoneSet: "",
        delSignRequired: false,
        printDispatchTick: false,
        orderDispatch: false,
        notReceivedMsgAlert: false,
        notReadMsgAlert: false,
        skipPickupStops: false,
        skipDelRoundStops: false,
        availCust: false,
        discInvoiceLevel: false,
        isActive: true,
        comment: "",
      });
    }
  }, [data]);

  const weekDayCheckBox = () => {
    const weekDays: string[] = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let weekDayCheckboxes = [];
    for (let day of weekDays) {
      weekDayCheckboxes.push(
        <div className="form__checkbox__option">
          <input
            type="checkbox"
            id={`AvailOn${day}`}
            className="form__checkbox__input"
            checked={formData.serviceDays.includes(day)}
            onChange={() => handleWeekDayChanges(day)}
          />
          <label className="form__checkbox__label" htmlFor={`AvailOn${day}`}>
            Avail On {day}
          </label>
        </div>
      );
    }
    return weekDayCheckboxes;
  };

  const messageSettingCheckbox = () => {
    const messageSettingOptions: string[] = [
      "Del Sign Required",
      "Print Dispatch Tick",
      "Order Dispatch",
      "Not Received Msg Alert",
      "Not Read Msg Alert",
      "Skip Pickup Stops",
      "Skip Del Round Stops",
      "Disc Invoice Level",
      "Avail Cust",
    ];

    const messageSettingCheckboxes = [];

    for (let msgOpt of messageSettingOptions) {
      const classid = msgOpt
        .replace(/\s+/g, "")
        .replace(/^([A-Z])/, (match) => match.toLowerCase());
      messageSettingCheckboxes.push(
        <div className="form__checkbox__option">
          <input
            type="checkbox"
            id={classid}
            checked={!!(formData as any)[classid]}
            className="form__checkbox__input"
            onChange={handleFieldChange}
          />
          <label className="form__checkbox__label" htmlFor={classid}>
            {msgOpt}
          </label>
        </div>
      );
    }
    return messageSettingCheckboxes;
  };
  const handleColorFocus = () => setIsDivFocused(true);
  const handleColorBlur = () => setIsDivFocused(false);
  const handleTypeFocus = () => setIsTypeFocused(true);
  const handleTypeBlur = () => setIsTypeFocused(false);
  useEffect(() => {
    if (formData?.id && vehicleRowData?.id !== data?.vehicleType?.id) {
      setHasChanges(true);
    }
  }, [vehicleRowData]);

  return (
    <div className="modal modal--hazmatDetail modal--open">
      <div className="modal__dialog">
        <div className="modal__content modal__content--lg">
          <div className="modal__header">
            <h4 className="modal__header--title">
              {formData.id ? "Edit Service" : "Add Service"}
            </h4>
            <button
              className="btn btn__transparent btnClose"
              onClick={handleModalClose}
            >
              <XCrossIcon />
            </button>
          </div>
          <div className="modal__body">
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="serviceName" className="form__label">
                    Service Name<span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="serviceName"
                    className={
                      formData?.id
                        ? "form__input form__input--disabled form__input--sm"
                        : "form__input form__input--sm"
                    }
                    placeholder="Service Name"
                    value={formData.serviceName}
                    disabled={formData?.id ? true : false}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div className="formCol">
                <MutliDropdown
                  columns={vehicleTypeColumns}
                  data={vehicleTypeDropdown}
                  setData={setVehicleTypeDropdown}
                  captionTitles="Select Vehicle Type"
                  rowData={vehicleRowData}
                  setRowData={setVehicleRowData}
                  displayColumn="name"
                  apiUrl={"service/vehicletype"}
                  labelTitle="Vehicle Type"
                  //enableRemove={true}
                  mandatory={true}
                  type={"dropdown"}
                  extendedUrl={{
                    ["agentTypeName"]: "Final Mile,Expedited",
                  }}
                  //   disable={id || tabSelect !== "loginDetails" ? true : false}
                />
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="shortName" className="form__label">
                    Short Name
                  </label>
                  <input
                    type="text"
                    id="shortName"
                    className="form__input form__input--sm"
                    placeholder="Short Name"
                    value={formData.shortName}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="longName" className="form__label">
                    Long Name
                  </label>
                  <input
                    type="text"
                    id="longName"
                    className="form__input form__input--sm"
                    placeholder="Long Name"
                    value={formData.longName}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="deliverWithin" className="form__label">
                    Deliver Within(Hours)
                    <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="deliverWithin"
                    className="form__input form__input--sm"
                    placeholder="Deliver Within(Hours)"
                    value={formData.deliverWithin}
                    onChange={handleFieldChange}
                  />
                  <span className="form__error">{errors.deliverWithin}</span>
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="dispatchTicketColor" className="form__label">
                    Dispatch Color
                  </label>
                  <div
                    className="form__groupField form__groupField--colorPicker"
                    onFocus={handleColorFocus}
                    onBlur={handleColorBlur}
                    style={isDivFocused ? { border: "solid 1px" } : {}}
                  >
                    <div className="colorPickerInfo">
                      <input
                        type="color"
                        id="dispatchTicketColor"
                        value={formData?.dispatchTicketColor || "#bb0707"}
                        className="form__input form__input--sm"
                        placeholder="#bb0707"
                        onChange={handleFieldChange}
                      />
                      <span className="colorPickerInfo__colorName">
                        {formData.dispatchTicketColor}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="dispatchPrinter" className="form__label">
                    Dispatch Printer
                  </label>
                  <input
                    type="text"
                    id="dispatchPrinter"
                    className="form__input form__input--sm"
                    placeholder="Dispatch Printer"
                    value={formData.dispatchPrinter}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div className="formCol">
                <div className="formRow formRow__2">
                  <div className="formCol">
                    <label htmlFor="agentType" className="form__label">
                      Agent Type <span className="mandatory-mark">*</span>
                    </label>
                    <select
                      id="agentType"
                      name="agentType"
                      className={`form__select form__select--sm`}
                      //   { id ? "form__select--disabled" : ""}
                      value={formData?.agentType}
                      onFocus={handleTypeFocus}
                      onBlur={handleTypeBlur}
                      style={isTypeFocused ? { border: "solid 1px" } : {}}
                      onChange={(e) => handleAgentTypeChange(e)}
                      //   disabled={id ? true : false}
                    >
                      <option>-Select-</option>
                      {agentType.map((item: any) => (
                        <option
                          key={`key-agent-type-${item.id}`}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <span className="form__error">
                      {/* {errors?.agentType &&
                        //touched?.agentType &&
                        errors?.agentType} */}
                    </span>
                  </div>
                  <div className="formCol">
                    <div className="form__Field">
                      <div className="form__label">Use Over 150 Lbs</div>
                      <div className="form__switchBtn form__switchBtn--verticalMiddle">
                        <div className="form__switchBtn__option">
                          <div className="switchBtn">
                            <input
                              //   checked
                              type="checkbox"
                              className="toggle"
                              id="useOver150Lbs"
                              checked={formData.useOver150Lbs}
                              onChange={handleFieldChange}
                            />
                            <label htmlFor="useOver150Lbs">
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="description" className="form__label">
                    Description<span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="description"
                    value={formData.description}
                    className="form__input form__input--sm"
                    placeholder="Description"
                    onChange={handleFieldChange}
                    maxLength={200}
                  />
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="comment" className="form__label">
                    Comment
                  </label>
                  <input
                    type="text"
                    id="comment"
                    className="form__input form__input--sm"
                    placeholder="Comment"
                    value={formData.comment}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <h3 className="title title--h5">Service Days</h3>
              </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <div className="form__checkbox form__checkbox--gray">
                  {weekDayCheckBox()}
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <h3 className="title title--h5">Message Settings</h3>
              </div>
            </div>
            <div className="formRow formRow__3">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="minsNotReceiveMsg" className="form__label">
                    Mins Not Receive Msg
                  </label>
                  <input
                    type="text"
                    id="minsNotReceiveMsg"
                    className="form__input form__input--sm"
                    placeholder="Mins Not Receive Msg"
                    value={formData.minsNotReceiveMsg}
                    onChange={handleFieldChange}
                  />
                </div>
                <span className="form__error">{errors?.minsNotReceiveMsg}</span>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="minsNotReadMsg" className="form__label">
                    Mins Not Read Msg
                  </label>
                  <input
                    type="text"
                    id="minsNotReadMsg"
                    className="form__input form__input--sm"
                    placeholder="Mins Not Read Msg"
                    value={formData.minsNotReadMsg}
                    onChange={handleFieldChange}
                  />
                </div>
                <span className="form__error">{errors?.minsNotReadMsg}</span>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="zoneSet" className="form__label">
                    Zone Set
                  </label>
                  <input
                    type="text"
                    id="zoneSet"
                    className="form__input form__input--sm"
                    placeholder="Zone Set"
                    value={formData.zoneSet}
                    onChange={handleFieldChange}
                  />
                </div>
                <span className="form__error">{errors?.zoneSet}</span>
              </div>
            </div>
            <div className="formRow">
              <div className="formCol">
                <div className="form__checkbox form__checkbox--gray">
                  {messageSettingCheckbox()}
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <div className="form__switchBtn">
                    <div className="form__switchBtn__option">
                      <div className="form__label">Active</div>
                      <div className="switchBtn">
                        <input
                          type="checkbox"
                          className="toggle"
                          name="isActive"
                          id="isActive"
                          checked={formData.isActive}
                          onChange={handleFieldChange}
                        />
                        <label htmlFor="isActive">
                          <span className="on">Yes</span>
                          <span className="off">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <div className="btn__group">
              <button className="btn btn__white" onClick={handleModalClose}>
                Cancel
              </button>
              <button
                className="btn btn__primary"
                disabled={isButtonDisabled}
                onClick={handleSubmit}
              >
                {formData?.id ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default ServicesModal;
