
interface ColumnTypes {
  key: string;
  title: string;
}

export interface AgentRowDataType {
  id?: string;
  name: string | null;
  agentType: "";
}

export interface AgentHubDropdown {
  id?: string;
  name: string | null;
  agentHubNo: string;
}

export const agentHubColumns: ColumnTypes[] = [{ key: "name", title: "Name" }];

export const agentOrderColumns: ColumnTypes[] = [
  { key: "name", title: "Agent Name" },
  { key: "agentType", title: "Agent Type" },
];

export const ApiTypeOptions = [
  { name: "Dispatch Track", id: "dispatchTrack" },
  { name: "Xcelerator API", id: "xceleratorApi" },
];

export const agentApiColumns = [
  { key: "count", header: "S.", isSortable: false, className: "count" },
  {
    key: "agentName",
    header: "Agent Name",
    isSortable: true,
    className: "profileLink",
  },
  {
    key: "hubName",
    header: "Hub Name",
    isSortable: true,
    className: "dark",
  },
  {
    key: "apiType",
    header: "API Type",
    isSortable: true,
    className: "dark",
    upperCase: true,
  },
  {
    key: "clientCode",
    header: "Client Code",
    isSortable: true,
    className: "dark",
  },
  {
    key: "clientApiKey",
    header: "Client API Key",
    isSortable: true,
    className: "dark",
  },
  {
    key: "active",
    header: "Active",
    isSortable: false,
    isActive: true,
    handleActive: false,
    className: "dark",
  },

]

export const agentApiServicesColumns = [
  { key: "count", header: "S.", isSortable: false, className: "count" },
  {
    key: "dtsServiceName",
    header: "DTS Service Name",
    isSortable: true,
    className: "dark",
  },
  // {
  //   key: "dtServiceName",
  //   header: "DT Service Name",
  //   isSortable: true,
  //   className: "dark",
  // },
  {
    key: "dtServiceCode",
    header: "DT Service Code",
    isSortable: true,
    className: "dark",
    upperCase: true,
  },
  // {
  //   key: "serviceDuration",
  //   header: "Service Duration",
  //   isSortable: true,
  //   className: "dark",
  // },
  {
    key: "weekdays",
    header: "Weekdays",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  {
    key: "saturdays",
    header: "Saturdays",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  {
    key: "sundays",
    header: "Sundays",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  {
    key: "holidays",
    header: "Holidays",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  {
    key: "active",
    header: "Active",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  
]

export const agentXCApiServicesColumns = [
  { key: "count", header: "S.", isSortable: false, className: "count" },
  {
    key: "dtsServiceName",
    header: "DTS Service Name",
    isSortable: true,
    className: "dark",
  },
  // {
  //   key: "xcServiceName",
  //   header: "XC Service Name",
  //   isSortable: true,
  //   className: "dark",
  // },
  {
    key: "dtServiceCode",
    header: "XC Service Code",
    isSortable: true,
    className: "dark",
    upperCase: true,
  },
  // {
  //   key: "serviceDuration",
  //   header: "Service Duration",
  //   isSortable: true,
  //   className: "dark",
  // },
  {
    key: "weekdays",
    header: "Weekdays",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  {
    key: "saturdays",
    header: "Saturdays",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  {
    key: "sundays",
    header: "Sundays",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  {
    key: "holidays",
    header: "Holidays",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  {
    key: "active",
    header: "Active",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
  
]

export interface XceleratorLoginDetailsField {
  apiId: string;
  apiUrl: string;
  apiAccountNo: string;
  apiLogin: string;
  apiPassword: string;
  startDate: string;
  active: boolean;
  version: string;
  useExpedieteOrders: boolean;
}

export interface XceleratorLoginDetailsProp {
  apiData: {
    agentId: string;
    agentHubId: string;
    apiId: string;
    apiIdDetails: any;
    setApiId: (apiId: string) => void
  };
}

export interface DispatchLoginDetailsField {
  apiId: string;
  clientCode: string;
  clientUrl: string;
  clientApi: string;
  postUrl: string;
  divLoc: string;
  updateUrl: string;
  inTransitStatus: string;
  startDate: string;
  customerName: string;
  dTSCode: string;
  billToId: string;
  active: boolean;
  inTransit: boolean;
  useDTsCode: boolean;
}

export interface DispatchLoginDetailsProp {
  apiData: {
    agentId: string;
    agentHubId: string;
    apiId: string;
    apiIdDetails: any;
    setApiId: (apiId: string) => void;
  };
}

export const agentApiPackageTypeColumns = [
  { key: "count", header: "S.", isSortable: false, className: "count" },
  {
    key: "dtsPackageCode",
    header: "DTS Package Code",
    isSortable: true,
    className: "dark",
  },
  {
    key: "xcPackageCode",
    header: "XC Package Code",
    isSortable: true,
    className: "dark",
  },
  {
    key: "xcPackageId",
    header: "XC Package ID",
    isSortable: true,
    className: "dark",
  },
  {
    key: "status",
    header: "Status",
    isSortable: true,
    className: "dark",
  },
  {
    key: "height",
    header: "Height",
    isSortable: true,
    className: "dark",
  },
  {
    key: "weight",
    header: "Weight",
    isSortable: true,
    className: "dark",
  },
  {
    key: "length",
    header: "Length",
    isSortable: true,
    className: "dark",
  },
  {
    key: "width",
    header: "Width",
    isSortable: true,
    className: "dark",
  },
  {
    key: "active",
    header: "Active",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
];