import React from "react";
import { XCrossIcon } from "../../assets";

interface RowDataType {
  serial: number;
  serviceName: string;
  description: string;
  serviceLevel: string;
  quotePrice: any;
  agentName: string;
  basePrice: any;
  fuelSurcharge: any;
  additionalOrderCharges?: { calculatedCharges: any[] };
}

interface PriceDetailsModalType {
  rowData?: RowDataType;
  setConfirmModal: (value: boolean) => void;
}

const PriceDetailsModal: React.FC<PriceDetailsModalType> = (props) => {
  const { rowData, setConfirmModal } = props;

  const handleClose = () => {
    setConfirmModal(false);
  };

  return (
    <div className="modal modal--hazmatDetail modal--open">
      <div className="modal__dialog">
        <div className="modal__content modal__content--lg">
          <div className="modal__header">
            <h4 className="modal__header--title">Price Details</h4>
            <button
              className="btn btn__transparent btnClose"
              onClick={handleClose}
            >
              <XCrossIcon />
            </button>
          </div>
          <div className="modal__body modal-body-left">
            <div className="orderPriceDetial">
              <h3 className="title title--h5">
                Agent: {rowData?.agentName ?? ""}
              </h3>
              <h4 className="title title--h5">
                Service: {rowData?.serviceName ?? ""}
              </h4>
              <div className="orderPriceDetial__history">
                <div className="orderPriceDetial__history__body">
                  <div className="item">
                    <div className="text">Fuel Surcharge</div>
                    <div className="value">
                      ${parseFloat(rowData?.fuelSurcharge)?.toFixed(2)}
                    </div>
                  </div>
                  {rowData?.additionalOrderCharges &&
                    rowData?.additionalOrderCharges.calculatedCharges &&
                    rowData.additionalOrderCharges.calculatedCharges?.map(
                      (additionalCharge) => (
                        <div className="item">
                          <div className="text">{additionalCharge.name}</div>
                          <div className="value">
                            {" "}
                            ${parseFloat(additionalCharge?.value)?.toFixed(2)}
                          </div>
                        </div>
                      )
                    )}
                  <div className="item">
                    <div className="text">Base Price</div>
                    <div className="value">
                      ${parseFloat(rowData?.basePrice).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="orderPriceDetial__history__footer">
                  <div className="item">Total Price</div>
                  <div className="item">
                    ${parseFloat(rowData?.quotePrice)?.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default PriceDetailsModal;
