import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../navbar/Navbar";
import SideBar from "../sidebar/SideBar";
import { RootState } from "../../redux/store";
import { Pagination } from "../shared";
import {
  setPage,
  setTotalItems,
  setTotalPages,
} from "../../redux/reducer/pagination/paginationSlice";

import "../../assets/scss/pages/dashboard.scss";

// Type for the updated pathsWithPagination structure
type PathWithTabs = {
  path: string | RegExp;
  activeTabs: string[];
};

const Layout: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state: RootState) => state.activeTab);

  const pathsWithPagination: PathWithTabs[] = [
    {
      path: "/users",
      activeTabs: [],
    },
    {
      path: "/customers-doc",
      activeTabs: [],
    },
    {
      path: "/customer-users",
      activeTabs: [],
    },
    {
      path: "/customers",
      activeTabs: [],
    },
    {
      path: /^\/customers\/customers(\/[^/]+)?$/,
      activeTabs: [],
    },
    {
      path: "/agent-list",
      activeTabs: [],
    },
    {
      path: "/agent-rates",
      activeTabs: [],
    },
    {
      path: "/agent-hubs",
      activeTabs: [],
    },
    {
      path: "/agent-doc-list",
      activeTabs: [],
    },
    {
      path: "/agent-mile-zone",
      activeTabs: [],
    },
    {
      path: "/agent-mile-zone/add",
      activeTabs: [],
    },
    {
      path: /^\/agent-mile-zone\/edit\/[a-zA-Z0-9!@#$%^&*()-_=+[{\]}\\|;:'",.<>/?]+$/,
      activeTabs: [],
    },
    {
      path: "/agent-weight-zone",
      activeTabs: [],
    },
    {
      path: "/agent-weight-zone/add",
      activeTabs: [],
    },
    {
      path: /^\/agent-weight-zone\/edit\/[a-zA-Z0-9!@#$%^&*()-_=+[{\]}\\|;:'",.<>/?]+$/,
      activeTabs: [],
    },
    {
      path: "/fsc-agent-list",
      activeTabs: [],
    },
    {
      path: "/fsc-agent-list/add",
      activeTabs: [],
    },
    {
      path: /^\/fsc-agent-list\/edit\/[a-zA-Z0-9!@#$%^&*()-_=+[{\]}\\|;:'",.<>/?]+$/,
      activeTabs: [],
    },
    {
      path: "/customer-order-history",
      activeTabs: [],
    },
    {
      path: "/master-agent-list",
      activeTabs: [],
    },
    {
      path: "/dispatch-board-list",
      activeTabs: [],
    },
    {
      path: /^\/agent-list(\/[^/]+)?$/,
      activeTabs: [
        "agentHubs",
        "agentMileZone",
        "agentWeightZone",
        "fscAgentList",
        "agentRates",
        "agentAdditionalCharges",
      ],
    },
    {
      path: /^\/master-agent-list(\/[^/]+)?$/,
      activeTabs: [
        "agentHubs",
        "agentMileZone",
        "agentWeightZone",
        "fscAgentList",
        "agentRates",
      ],
    },
    {
      path: /^\/customer\/rate\/(\/[^/]+)?$/,
      activeTabs: [
        "fscCustomerList",
        "customerAdditionalCharges",
        "customerRate",
      ],
    },
    {
      path: "/billed-order-history",
      activeTabs: [],
    },
    {
      path: "/Xcelarator-api",
      activeTabs: [],
    },
    {
      path: "/agent-api",
      activeTabs: [],
    },
    {
      path: /^\/Xcelarator-api\/agent(\/[^/]+)?$/,
      activeTabs: ["services", "vehicle", "packageType", "apiOrderStatus"],
    },
    {
      path: "/customer-markup",
      activeTabs: [],
    },
    {
      path: "/group-invoice-list",
      activeTabs: [],
    },
    {
      path: "/invoice-list",
      activeTabs: [],
    },
    {
      path: "/finalized-orders",
      activeTabs: [],
    },
    {
      path: "/settings-additional-charges",
      activeTabs: [],
    },
    {
      path: "/create-report",
      activeTabs: [],
    },
    {
      path: "/saved-reports",
      activeTabs: [],
    },
    {
      path: "/system-configuration/package-type",
      activeTabs: [],
    },
    {
      path: "/system-configuration/services",
      activeTabs: [],
    },
    {
      path: "/save-quotation",
      activeTabs: [],
    },
  ];

  /**
   * Determine if pagination should be rendered based on path and active tab
   */
  const shouldRenderPagination = pathsWithPagination.some(
    ({ path, activeTabs }) => {
      const isPathMatch =
        typeof path === "string"
          ? location.pathname === path
          : path instanceof RegExp
          ? path.test(location.pathname)
          : false;
      // Render pagination if path matches and either activeTabs is empty or activeTab is included in activeTabs
      return (
        (isPathMatch && activeTabs.length === 0) ||
        (activeTab && activeTabs.length > 0 && activeTabs.includes(activeTab))
      );
    }
  );

  /**
   * Determines the appropriate class name for the page based on the current route and pagination status.
   *
   * @param {string} pathname - The current path (route) of the application.
   * @param {boolean} shouldRenderPagination - A boolean indicating whether pagination should be rendered.
   *
   * @returns {string} - The class name that should be applied to the page container.
   *    Returns:
   *    - "page__fullBody" if pagination should be rendered.
   *    - "page__full" if pagination should not be rendered.
   *    - An empty string if the route is in the list of exceptions where 'page__full' should not be applied.
   */
  const getPageClassName = (
    pathname: string,
    shouldRenderPagination: boolean
  ): string => {
    // List of routes that should not have the 'page__full' class
    const noFullClassRoutes = ["/email-notification-permissions"];

    // Check if the current path is in the list
    if (noFullClassRoutes.includes(pathname)) {
      return ""; // Apply a different class or no class
    }

    // Apply 'page__full' if pagination should not be rendered, otherwise 'page__fullBody'
    return shouldRenderPagination ? "page__fullBody" : "page__full";
  };

  // Reset pagination data when the current location changes
  useEffect(() => {
    dispatch(setTotalItems(0));
    dispatch(setTotalPages(1));
    dispatch(setPage(1));
  }, [location.pathname]);

  return (
    <>
      <section className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <div
            className={`page ${getPageClassName(
              location.pathname,
              shouldRenderPagination
            )}`}
          >
            <Navbar />
            <div className="page__body">
              {/* <HeaderFilters /> */}
              <Outlet />
            </div>
            {shouldRenderPagination && <Pagination />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Layout;
