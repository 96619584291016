import React, { useState } from "react";
import { XCrossIcon } from "../../assets";

interface RowDataType {
  serial: number;
  serviceName: string;
  carrierName: string;
  description: string;
  serviceLevel: string;
  quotePrice: any;
  agentName: string;
  price: any;
  markup: number;
  priceWithMarkUp: string;
  fuelSurcharge: any;
  additionalCharges?: any[];
  additionalOtherCharges?: { calculatedCharges: any[] };
  basePrice?: string;
  priceWithFinalMile?: string;
}

interface PriceDetailsModalType {
  rowData?: RowDataType;
  setConfirmModal: (value: boolean) => void;
}

const LTLPriceDetailsModal: React.FC<PriceDetailsModalType> = (props) => {
  const { rowData, setConfirmModal } = props;
  const handleClose = () => {
    setConfirmModal(false);
  };
  const [details, setDetails] = useState<any>(rowData);
  console.log(details);
  return (
    <div className="modal modal--hazmatDetail modal--open">
      <div className="modal__dialog">
        <div className="modal__content modal__content--lg">
          <div className="modal__header">
            <h4 className="modal__header--title">Price Details</h4>
            <button
              className="btn btn__transparent btnClose"
              onClick={handleClose}
            >
              <XCrossIcon />
            </button>
          </div>
          <div className="modal__body modal-body-left">
            <div className="orderPriceDetial">
              <h3 className="title title--h5">
                Carrier Name: {rowData?.carrierName ?? ""}
              </h3>
              <h4 className="title title--h5">Service: {details["mode"]}</h4>
              {!rowData || !rowData.quotePrice ? (
                <div
                  className="orderPriceDetial__history"
                  style={{ marginLeft: "2px" }}
                >
                  <div className="orderPriceDetial__history__header">
                    <div className="item">Charge</div>
                    <div className="item">Price</div>
                  </div>
                  <div className="orderPriceDetial__history__body">
                    {rowData?.additionalCharges &&
                      rowData.additionalCharges.map((additionalCharge) => (
                        <div className="item">
                          <div className="text">
                            {additionalCharge.customerDescription}
                          </div>
                          <div className="value">
                            {" "}
                            $
                            {parseFloat(
                              additionalCharge?.customerPrice?.value
                            )?.toFixed(2)}
                          </div>
                        </div>
                      ))}
                    {/* {rowData?.markup && rowData?.markup > 0 ? (
                      <div className="item">
                        <div className="text">Markup</div>
                        <div className="value">
                          ${parseFloat(rowData?.markup.toFixed(2)).toFixed(2)}
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="orderPriceDetial__history__footer">
                    <div className="item">Total Price</div>
                    <div className="item">
                      $
                      {parseFloat(
                        rowData?.priceWithMarkUp || rowData?.price
                      )?.toFixed(2)}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="formRow formRow__2">
                    <div className="formCol">
                      <div className="orderPriceDetial__history">
                        <div className="orderPriceDetial__history__header">
                          <div className="item">Charge(LTL)</div>
                          <div className="item">Price</div>
                        </div>
                        <div className="orderPriceDetial__history__body">
                          {rowData?.additionalCharges &&
                            rowData.additionalCharges.map(
                              (additionalCharge) => (
                                <div className="item">
                                  <div className="text">
                                    {additionalCharge.customerDescription}
                                  </div>
                                  <div className="value">
                                    {" "}
                                    $
                                    {parseFloat(
                                      additionalCharge?.customerPrice?.value
                                    )?.toFixed(2)}
                                  </div>
                                </div>
                              )
                            )}
                          {/* {rowData?.markup && rowData?.markup > 0 ? (
                            <div className="item">
                              <div className="text">Markup</div>
                              <div className="value">
                                $
                                {parseFloat(rowData?.markup.toFixed(2)).toFixed(
                                  2
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                        <div className="orderPriceDetial__history__footer">
                          <div className="item">Price</div>
                          <div className="item">
                            $
                            {parseFloat(
                              rowData?.priceWithMarkUp || rowData?.price
                            )?.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="orderPriceDetial__history">
                        <div className="orderPriceDetial__history__header">
                          <div className="item">Charge(Final Mile)</div>
                          <div className="item">Price</div>
                        </div>
                        <div className="orderPriceDetial__history__body">
                          {rowData?.quotePrice && rowData?.quotePrice > 0 ? (
                            <div className="item">
                              <div className="text">Base Price</div>
                              {/* <div className="value">${rowData?.markup}</div> */}
                              <div className="value">
                                {" "}
                                $
                                {parseFloat(rowData?.basePrice || "0")?.toFixed(
                                  2
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="item">
                            <div className="text">Fuel Surcharge</div>
                            <div className="value">
                              ${parseFloat(rowData?.fuelSurcharge)?.toFixed(2)}
                            </div>
                          </div>
                          {rowData?.additionalOtherCharges &&
                            rowData?.additionalOtherCharges.calculatedCharges &&
                            rowData.additionalOtherCharges.calculatedCharges?.map(
                              (additionalCharge) => (
                                <div className="item">
                                  <div className="text">
                                    {additionalCharge.name}
                                  </div>
                                  <div className="value">
                                    {" "}
                                    $
                                    {parseFloat(
                                      additionalCharge?.value
                                    )?.toFixed(2)}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                        <div className="orderPriceDetial__history__footer">
                          <div className="item">Price</div>
                          <div className="item">
                            $
                            {parseFloat(
                              rowData?.quotePrice || rowData?.price
                            )?.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="title title--h5">
                    Total Price: $
                    {parseFloat(rowData?.priceWithFinalMile || "0")?.toFixed(2)}
                  </h3>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default LTLPriceDetailsModal;
