import React, { useMemo, useState } from "react";

import { ArrowDownLightIcon, ArrowUpLightIcon } from "../../assets";
import LTLPriceDetailsModal from "../modals/LTLPriceDetailsModal";
import PriceDetailsModal from "../modals/PriceDetailsModal";

interface GetSavedQuotePrp {
  data: any;
  columns: any;
  orderType: string;
  handleSelect: (quoteData: any) => void;
}

const GetSavedQuote: React.FC<GetSavedQuotePrp> = ({
  data,
  columns,
  orderType,
  handleSelect
}) => {
  const [viewPriceDetailsModal, setViewPriceDetailsModal] =
    useState<boolean>(false);
  const [viewDetailRowData, setViewDetailRowData] = useState<any>();
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>({
    key: "quotePrice",
    direction: "ascending"
});

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (!sortConfig) return <ArrowDownLightIcon />;
    return sortConfig.key === key ? (
      sortConfig.direction === "ascending" ? (
        <ArrowUpLightIcon />
      ) : (
        <ArrowDownLightIcon />
      )
    ) : (
      <ArrowDownLightIcon />
    );
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  /**
   * Handles the action of viewing details of a specific row in the table.
   * @param rowData - The data of the row to view in detail.
   */
  const handleViewDetails = (rowData: any) => {
    if (orderType === "Final Mile") {
      setViewDetailRowData({
        agentName: rowData?.agentName,
        serviceName: rowData?.serviceName,
        fuelSurcharge: rowData?.fuelSurcharge,
        additionalOrderCharges: rowData?.additionalOtherCharges,
        quotePrice: rowData?.quotePrice,
        basePrice: rowData?.basePrice
      });
    } else {
      
    }
    setViewPriceDetailsModal(true);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            {columns.map((item: any) =>
              item?.sortable ? (
                <th>
                  <div
                    className="sortingDiv"
                    onClick={() => requestSort(item.key)}
                  >
                    {item?.header}
                    <span className="sortingDiv__icon">
                      {getSortIcon(item.key)}
                    </span>
                  </div>
                </th>
              ) : item?.className === "expired" ? (
                <th>
                  <div className="sortingDiv sortingDiv--center">
                    {item?.header}
                  </div>
                </th>
              ) : (
                <th>{item?.header}</th>
              )
            )}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((rowItem: any, index: number) => {
            return (
              <tr>
                {columns.map((item: any) => {
                  return item.className === "expired" ? (
                    rowItem[item.key] ? (
                      <td>
                        <div className="actions flex--center flex--expired">
                          Expired
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div className="actions flex--center flex--valid">Valid</div>
                      </td>
                    )
                  ) : item.className === "count" ? (<td>
                    <div className="cellText">
                      <span className="cellText__dark cellText__dark--gray">
                        {index+1}
                      </span>
                    </div>
                  </td>) : (
                    <td>
                      <div className="cellText">
                        <span className="cellText__dark cellText__dark--gray">
                          {item?.dollar && rowItem[item.key]
                            ? "$" + rowItem[item.key].toFixed(2)
                            : rowItem[item.key]}
                        </span>
                      </div>
                    </td>
                  );
                })}
                <td>
                  <div className="actions flex--center">
                    {
                      orderType === "Final Mile" ? <button
                      className="link link__primary link--bold"
                      onClick={() => handleViewDetails(rowItem)}
                    >
                      View
                    </button> : ""
                    }
                    <button 
                      className="btn btn__primary btn__outlined btn--medium"
                      onClick={()=>handleSelect({id:rowItem['quoteGroupById'], quoteId: rowItem['quoteId']})}
                    >
                      Select
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
        {data.orderType}
      </table>
      {viewPriceDetailsModal && orderType === "Final Mile" ? (
        <PriceDetailsModal
          setConfirmModal={setViewPriceDetailsModal}
          rowData={viewDetailRowData}
        />
      ) : viewPriceDetailsModal ? (
        <LTLPriceDetailsModal
          setConfirmModal={setViewPriceDetailsModal}
          rowData={viewDetailRowData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default GetSavedQuote;
