import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import MultiSelectChips from "./MultiSelectChips";
import { DropwdownInitailValueTypes } from "../../hooks/useSearchAndFilter";
import useOutsideClick from "../../hooks/useOutsideClick";

interface FilterModalProps {
  isShowFilterModal?: boolean;
  closeOpenfilterModal?: () => void;
  selectStatus?: DropwdownInitailValueTypes;
  setSelectStatus?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes>
  >;
  selectApiType?: DropwdownInitailValueTypes;
  setSelectApiType?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes>
  >;
  selectedRoles?: DropwdownInitailValueTypes[];
  setSelectedRoles?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  rolesDropdownData?: DropwdownInitailValueTypes[];
  setRolesDropdownData?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  fetchFilteredData?: (
    currentPage?: number,
    status?: DropwdownInitailValueTypes,
    roles?: DropwdownInitailValueTypes[],
    orderStatus?: DropwdownInitailValueTypes[],
    orderType?: DropwdownInitailValueTypes[],
    agentType?: DropwdownInitailValueTypes[],
    hubs?: DropwdownInitailValueTypes[],
    services?: DropwdownInitailValueTypes[],
    customerSelectedLevel?: DropwdownInitailValueTypes,
    customerTypeLevel?: DropwdownInitailValueTypes[],
    apiType?: any
  ) => void;
  isStatusHide?: boolean;
  isCustomerTypeHide?: boolean;
  isRoleHide?: boolean;
  isOrderStatusHide?: boolean;
  isAgentTypeHide?: boolean;
  isOrderTypeHide?: boolean;
  isHubHide?: boolean;
  isServiceHide?: boolean;
  roleApiUrl?: string;
  orderStatusApiUrl?: string;
  orderTypeApiUrl?: string;
  agentTypeApiUrl?: string;
  hubApiUrl?: string;
  serviceApiUrl?: string;
  orderStatusSelected?: DropwdownInitailValueTypes[];
  setOrderStatusSelected?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  orderStatusDropdownData?: DropwdownInitailValueTypes[];
  setOrderStatusDropdownData?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  orderTypeSelected?: DropwdownInitailValueTypes[];
  setOrderTypeSelected?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  orderTypeDropdownData?: DropwdownInitailValueTypes[];
  setOrderTypeDropdownData?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  agentTypeSelected?: DropwdownInitailValueTypes[];
  setAgentTypeSelected?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  agentTypeDropdownData?: DropwdownInitailValueTypes[];
  setAgentTypeDropdownData?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  hubSelected?: DropwdownInitailValueTypes[];
  setHubSelected?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  hubDropdownData?: DropwdownInitailValueTypes[];
  setHubDropdownData?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  serviceSelected?: DropwdownInitailValueTypes[];
  setServiceSelected?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  serviceDropdownData?: DropwdownInitailValueTypes[];
  setServiceDropdownData?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes[]>
  >;
  customerSelectedLevel?: DropwdownInitailValueTypes;
  setCustomerSelectedLevel?: React.Dispatch<
    React.SetStateAction<DropwdownInitailValueTypes>
  >;
  isApiTypeShow?: boolean;
}

/**
 * FilterModal component to handle filtering data based on various dropdowns.
 * It allows users to filter based on status, roles, order types, hubs, and service levels.
 *
 * @param isShowFilterModal - Boolean flag to control modal visibility
 * @param closeOpenfilterModal - Function to close the modal
 * @param selectStatus - Current selected status
 * @param setSelectStatus - Setter function for the selected status
 * @param selectedRoles - Current selected roles
 * @param setSelectedRoles - Setter function for selected roles
 * @param rolesDropdownData - Data for roles dropdown
 * @param setRolesDropdownData - Setter function for roles dropdown data
 * @param fetchFilteredData - Function to fetch filtered data based on selected filters
 * @param isStatusHide - Flag to hide the status dropdown
 * @param isCustomerTypeHide - Flag to hide the customer type dropdown
 * @param isRoleHide - Flag to hide the roles dropdown
 * @param isOrderStatusHide - Flag to hide the order status dropdown
 * @param isOrderTypeHide - Flag to hide the order type dropdown
 * @param isAgentTypeHide - Flag to hide the order type dropdown
 * @param isHubHide - Flag to hide the hub dropdown
 * @param isServiceHide - Flag to hide the service level dropdown
 * @param roleApiUrl - API URL for roles dropdown data
 * @param orderStatusApiUrl - API URL for order status dropdown data
 * @param orderTypeApiUrl - API URL for order type dropdown data
 * @param agentTypeApiUrl - API URL for order type dropdown data
 * @param hubApiUrl - API URL for hub dropdown data
 * @param serviceApiUrl - API URL for service level dropdown data
 * @param orderStatusSelected - Current selected order statuses
 * @param setOrderStatusSelected - Setter function for selected order statuses
 * @param orderStatusDropdownData - Data for order statuses dropdown
 * @param setOrderStatusDropdownData - Setter function for order status dropdown data
 * @param orderTypeSelected - Current selected order types
 * @param setOrderTypeSelected - Setter function for selected order types
 * @param orderTypeDropdownData - Data for order type dropdown
 * @param setOrderTypeDropdownData - Setter function for order type dropdown data
 * @param agentTypeSelected - Current selected order types
 * @param setAgentTypeSelected - Setter function for selected order types
 * @param agentTypeDropdownData - Data for order type dropdown
 * @param setAgentTypeDropdownData - Setter function for order type dropdown data
 * @param hubSelected - Current selected hubs
 * @param setHubSelected - Setter function for selected hubs
 * @param hubDropdownData - Data for hub dropdown
 * @param setHubDropdownData - Setter function for hub dropdown data
 * @param serviceSelected - Current selected services
 * @param setServiceSelected - Setter function for selected services
 * @param serviceDropdownData - Data for service level dropdown
 * @param setServiceDropdownData - Setter function for service dropdown data
 */
const FilterModal: React.FC<FilterModalProps> = ({
  isShowFilterModal,
  closeOpenfilterModal,
  selectStatus,
  setSelectStatus,
  selectApiType,
  setSelectApiType,
  selectedRoles,
  setSelectedRoles,
  rolesDropdownData,
  setRolesDropdownData,
  fetchFilteredData,
  isRoleHide,
  isStatusHide,
  isCustomerTypeHide,
  isOrderStatusHide,
  isOrderTypeHide,
  isAgentTypeHide,
  isHubHide,
  isServiceHide,
  isApiTypeShow,
  roleApiUrl,
  orderStatusApiUrl,
  orderTypeApiUrl,
  agentTypeApiUrl,
  hubApiUrl,
  serviceApiUrl,
  orderStatusSelected,
  setOrderStatusSelected,
  orderStatusDropdownData,
  setOrderStatusDropdownData,
  orderTypeSelected,
  setOrderTypeSelected,
  orderTypeDropdownData,
  setOrderTypeDropdownData,
  agentTypeSelected,
  setAgentTypeSelected,
  agentTypeDropdownData,
  setAgentTypeDropdownData,
  hubSelected,
  setHubSelected,
  hubDropdownData,
  setHubDropdownData,
  serviceSelected,
  setServiceSelected,
  serviceDropdownData,
  setServiceDropdownData,
  customerSelectedLevel,
  setCustomerSelectedLevel,
}) => {
  const { currentPage } = useSelector((state: RootState) => state.pagination);

  /**
   * Helper function to safely invoke optional functions if they exist.
   *
   * @param func - Optional function to invoke
   */
  const safeInvoke = (func?: () => void) => func && func();

  /**
   * Memoized function to check if at least one filter is selected.
   *
   * @returns {boolean} - True if at least one filter is selected, false otherwise.
   */
  const isAnyFilterSelected = useMemo(() => {
    return (
      selectStatus?.id ||
      selectApiType?.id ||
      customerSelectedLevel?.id ||
      (selectedRoles && selectedRoles.length > 0) ||
      (orderStatusSelected && orderStatusSelected.length > 0) ||
      (orderTypeSelected && orderTypeSelected.length > 0) ||
      (agentTypeSelected && agentTypeSelected.length > 0) ||
      (hubSelected && hubSelected.length > 0) ||
      (serviceSelected && serviceSelected.length > 0)
    );
  }, [
    selectStatus?.id,
    selectApiType?.id,
    customerSelectedLevel?.id,
    selectedRoles,
    orderStatusSelected,
    orderTypeSelected,
    agentTypeSelected,
    hubSelected,
    serviceSelected,
  ]);

  /**
   * Handles changes to the status select field.
   * Updates the selectStatus state with the selected option.
   *
   * @param e - The change event from the select dropdown
   */
  const handleSelectFieldChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const { value } = e.target;
    const selectedName =
      e.target.options[e.target.selectedIndex].getAttribute("data-name");
    if (fieldName === "selectedStatus") {
      setSelectStatus &&
        setSelectStatus?.({ name: selectedName ?? "", id: value });
    }
    if (fieldName === "selectApiType") {
      setSelectApiType &&
        setSelectApiType?.({ name: selectedName ?? "", id: value });
    }
    if (fieldName === "customerSelectedLevel") {
      setCustomerSelectedLevel &&
        setCustomerSelectedLevel?.({ name: selectedName ?? "", id: value });
    }
  };

  /**
   * Handles the Apply button click event.
   * Calls the fetchFilteredData function only if at least one filter is selected.
   */
  const handleApplyFilter = () => {
    if (isAnyFilterSelected) {
      fetchFilteredData &&
        fetchFilteredData(
          1,
          selectStatus,
          selectedRoles,
          orderStatusSelected,
          orderTypeSelected,
          agentTypeSelected,
          hubSelected,
          serviceSelected,
          customerSelectedLevel,
          [],
          selectApiType
        );
      safeInvoke(closeOpenfilterModal);
    }
  };

  /**
   * Handles the Clear button click event.
   * Resets the selected filters (status and roles) to their initial values and
   * calls fetchFilteredData to fetch the unfiltered data.
   */
  const handleClearFilter = () => {
    const clearedStatus: DropwdownInitailValueTypes = { name: "", id: "" };
    const clearedApiType: DropwdownInitailValueTypes = { name: "", id: "" };
    const clearedCustomerSelectedLevel: DropwdownInitailValueTypes = {
      name: "",
      id: "",
    };
    const clearedRoles: DropwdownInitailValueTypes[] = [];
    const clearedOrderStatus: DropwdownInitailValueTypes[] = [];
    const clearedLevelType: DropwdownInitailValueTypes[] = [];
    const clearedOrderType: DropwdownInitailValueTypes[] = [];
    const clearedAgentType: DropwdownInitailValueTypes[] = [];
    const clearedHub: DropwdownInitailValueTypes[] = [];
    const clearedService: DropwdownInitailValueTypes[] = [];

    // Update the state to reflect cleared filters
    setSelectStatus && setSelectStatus(clearedStatus);
    setSelectApiType && setSelectApiType(clearedApiType);
    setCustomerSelectedLevel &&
      setCustomerSelectedLevel(clearedCustomerSelectedLevel);
    setSelectedRoles && setSelectedRoles(clearedRoles);
    setOrderStatusSelected && setOrderStatusSelected(clearedOrderStatus);
    setOrderTypeSelected && setOrderTypeSelected(clearedOrderType);
    setAgentTypeSelected && setAgentTypeSelected(clearedAgentType);
    setHubSelected && setHubSelected(clearedHub);
    setServiceSelected && setServiceSelected(clearedService);

    fetchFilteredData &&
      fetchFilteredData?.(
        currentPage,
        clearedStatus,
        clearedRoles,
        clearedOrderStatus,
        clearedOrderType,
        clearedAgentType,
        clearedHub,
        clearedService,
        clearedCustomerSelectedLevel,
        clearedLevelType,
        clearedApiType
      );

    // Close the modal
    safeInvoke(closeOpenfilterModal);
  };

  /**
   * Render a MultiSelectChips component for filtering options.
   *
   * @param labelTitle - The label for the dropdown
   * @param apiUrl - The API URL to fetch dropdown data
   * @param dropdownData - The current dropdown data
   * @param selectedData - The currently selected items
   * @param setDropdownData - Setter function for the dropdown data
   * @param setSelectedData - Setter function for the selected items
   */
  const renderMultiSelectChips = (
    labelTitle: string,
    apiUrl: string,
    dropdownData: DropwdownInitailValueTypes[] | undefined,
    selectedData: DropwdownInitailValueTypes[] | undefined,
    setDropdownData:
      | React.Dispatch<React.SetStateAction<DropwdownInitailValueTypes[]>>
      | undefined,
    setSelectedData:
      | React.Dispatch<React.SetStateAction<DropwdownInitailValueTypes[]>>
      | undefined
  ) => (
    <MultiSelectChips
      apiUrl={apiUrl}
      dropdownData={dropdownData ?? []}
      selectedData={selectedData ?? []}
      setDropdownData={setDropdownData ?? (() => {})}
      setSelectedData={setSelectedData ?? (() => {})}
      searchPlaceholder="Search"
      labelTitle={labelTitle}
      keyBindName="name"
    />
  );

  // Ref to handle clicks outside the dropdown to close it
  const dropdownRef = useOutsideClick<HTMLDivElement>(() => {
    closeOpenfilterModal && closeOpenfilterModal();
  });

  // Early return if the modal should not be shown
  if (!isShowFilterModal) return null;

  return (
    <>
      <div
        className={`filter ${isShowFilterModal && "filter--open"} `}
        ref={dropdownRef}
      >
        <div className="filter__dropdown">
          <div className="filter__dropdown__detail">
            <div className="filter__dropdown__body">
              {!isStatusHide && (
                <div className="formRow">
                  <div className="formCol">
                    <div className="form__Field">
                      <label
                        htmlFor="selectedStatus"
                        className="form__label form__label--medium"
                      >
                        Select Status
                      </label>
                      <select
                        id="selectedStatus"
                        className="form__select form__select--sm"
                        value={selectStatus?.id}
                        onChange={(e) => {
                          handleSelectFieldChange(e, "selectedStatus");
                        }}
                        name="selectedStatus"
                      >
                        <option value="" data-name="">
                          -Select-
                        </option>
                        <option value="1" data-name="Active">
                          Active
                        </option>
                        <option value="0" data-name="Deactivate">
                          Inactive
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {isCustomerTypeHide && (
                <div className="formRow">
                  <div className="formCol">
                    <div className="form__Field">
                      <label
                        htmlFor="customerSelectedLevel"
                        className="form__label form__label--medium"
                      >
                        Select Customer Type
                      </label>
                      <select
                        id="customerSelectedLevel"
                        className="form__select form__select--sm"
                        value={customerSelectedLevel?.id}
                        onChange={(e) => {
                          handleSelectFieldChange(e, "customerSelectedLevel");
                        }}
                        name="customerSelectedLevel"
                      >
                        <option value="" data-name="">
                          -Select-
                        </option>
                        <option value="Customer" data-name="Customer">
                          Parent Customer
                        </option>
                        <option value="Associate" data-name="Associate">
                          Sub Customer
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {!isRoleHide &&
                renderMultiSelectChips(
                  "Select Role",
                  roleApiUrl ?? "",
                  rolesDropdownData,
                  selectedRoles,
                  setRolesDropdownData,
                  setSelectedRoles
                )}
              {!isOrderStatusHide &&
                renderMultiSelectChips(
                  "Select Order Status",
                  orderStatusApiUrl ?? "",
                  orderStatusDropdownData,
                  orderStatusSelected,
                  setOrderStatusDropdownData,
                  setOrderStatusSelected
                )}
              {!isOrderTypeHide &&
                renderMultiSelectChips(
                  "Select Order Type",
                  orderTypeApiUrl ?? "",
                  orderTypeDropdownData,
                  orderTypeSelected,
                  setOrderTypeDropdownData,
                  setOrderTypeSelected
                )}
              {!isAgentTypeHide &&
                renderMultiSelectChips(
                  "Select Agent Type",
                  agentTypeApiUrl ?? "",
                  agentTypeDropdownData,
                  agentTypeSelected,
                  setAgentTypeDropdownData,
                  setAgentTypeSelected
                )}
              {!isHubHide &&
                renderMultiSelectChips(
                  "Select Hub Name",
                  hubApiUrl ?? "",
                  hubDropdownData,
                  hubSelected,
                  setHubDropdownData,
                  setHubSelected
                )}
              {!isServiceHide &&
                renderMultiSelectChips(
                  "Select Service Level",
                  serviceApiUrl ?? "",
                  serviceDropdownData,
                  serviceSelected,
                  setServiceDropdownData,
                  setServiceSelected
                )}
              {isApiTypeShow && (
                <div className="formRow">
                  <div className="formCol">
                    <div className="form__Field">
                      <label
                        htmlFor="selectApiType"
                        className="form__label form__label--medium"
                      >
                        Select API Type
                      </label>
                      <select
                        id="selectApiType"
                        className="form__select form__select--sm"
                        value={selectApiType?.id}
                        onChange={(e) => {
                          handleSelectFieldChange(e, "selectApiType");
                        }}
                        name="selectApiType"
                      >
                        <option value="" data-name="">
                          -Select-
                        </option>
                        <option value="Dispatch Track" data-name="Dispatch Track">
                          Dispatch Track
                        </option>
                        <option value="Xcelerator Api" data-name="Xcelerator Api">
                          Xcelerator API
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="filter__dropdown__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={handleClearFilter}>
                  Clear
                </button>
                <button
                  className="btn btn__primary"
                  onClick={handleApplyFilter}
                  disabled={!isAnyFilterSelected}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterModal;
