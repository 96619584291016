import React, { useState } from "react";

import { SearchInput, Table, Pagination } from "../../../components/shared";

import { AddIcon, FilterIcon } from "../../../assets";
import useSearchAndFilter from "../../../hooks/useSearchAndFilter";
import { Loader } from "../../../components/loaders/loaders";
import AddVehicleType from "../../../components/modals/systemConfigurationModal/AddVehicleType";

interface OrderStatusResponse {
  id: string;
  count: number | string;
  active: boolean;
  orderStatus: {
    id: string;
    name: string;
    isActive: boolean;
  };
  dispatchTrackOrderStatus: string;
}
interface locationDataTypes {
  [key: string]: any;
}
interface OrderStatusProps {
  locationData: locationDataTypes;
}

const columns = [
  { key: "count", header: "S.", isSortable: false, className: "count" },
  {
    key: "name",
    header: "Vehicle Type",
    isSortable: true,
    className: "dark",
  },
  {
    key: "description",
    header: "Description",
    isSortable: true,
    className: "dark",
  },
  {
    key: "active",
    header: "Active",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
];

const xcColumns = [
  { key: "count", header: "S.", isSortable: false, className: "count" },
  {
    key: "dtsStatusCode",
    header: "DTS Status Code",
    isSortable: true,
    className: "dark",
  },
  {
    key: "xcStatusCode",
    header: "XC Status Code",
    isSortable: true,
    className: "dark",
  },
  {
    key: "active",
    header: "Active",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
];

const mapUserData = (data: any) =>
  data.map((item: any) => ({
    id: item?.id,
    count: item?.count,
    name: item?.name || "",
    description: item?.description || "",
    active: item?.isActive || false,
  }));

const tableConfig = {
  delete: {
    apiUrl: "/service/vehicletype/delete/",
    urlID: "id",
    successMessage: "Vehicle Type deleted successfully!",
    heading: "Delete Vehicle Type!",
    para: "delete",
    rowName: "name",
    secondRowName: "",
    deleteByPutApi: true
  },
};

interface AgentApiOrderStatusProps {
  apiData?: {
    agentId: string;
    agentHubId: string;
    orderDispatchCredId: string;
  };
  apiType?: {
    id: string;
    name: string;
  };
}

const VehicleType: React.FC<AgentApiOrderStatusProps> = ({
  apiData,
  apiType,
}) => {
  const locationData = {
    id: "12345",
  };
  /**
   * Custom hook for fetching data, handling search, and managing pagination.
   *
   * @param apiUrl - The URL to fetch data from.
   * @param limit - The maximum number of items to fetch.
   * @param mapUserData - Function to map the fetched data.
   *
   * @returns data - The fetched data for display in the table.
   * @returns keyword - The current search keyword.
   * @returns loading - Boolean indicating if data is being loaded.
   * @returns setLoading - Function to set the loading state.
   * @returns handleKeywordChange - Function to handle changes in the search input and debounce API calls.
   * @returns fetchFilteredData - Function to fetch data based on the current filters.
   * @returns setData - Function to set the data.
   */
  const {
    data,
    keyword,
    loading,
    setLoading,
    handleKeywordChange,
    fetchFilteredData,
  } = useSearchAndFilter<OrderStatusResponse>({
    apiUrl: "/service/vehicletype",
    limit: 15,
    mapUserData,
    // xceleratorId: locationData?.id,
    // dispatchCredId: apiType?.id === "xceleratorApi" ? "" : apiData?.orderDispatchCredId,
  });
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("Add");
  const [rowData, setRowData] = useState();

  const handleEditModal = (data: any) => {
    setModalType("Edit");
    setIsShowModal(true);
    setRowData(data);
  };

  return (
    <>
      <div className="tableWrap">
        <div className="tableWrap__head">
          <div className="formRow formRow__3 m_0 w_100 formRow--flexEnd">
            <div className="formCol">
              <SearchInput
                type="text"
                value={keyword}
                placeholder="Search a Vehicle"
                onChange={handleKeywordChange}
              />
            </div>
          </div>
          <button
            className="btn btn__white w_fitContent"
            onClick={() => {
              setModalType("Add");
              setIsShowModal(true);
            }}
          >
            <AddIcon />
            Create New
          </button>
        </div>
        <Table
          columns={columns}
          data={data}
          setLoading={setLoading}
          onDeleteConfig={tableConfig.delete}
          editModalHanlde={handleEditModal}
          getApiData={fetchFilteredData}
          disabledActive={true}
          deleteIcon={true}
          activeIcon={true}
          editIcon={true}
        />
        <Pagination />
      </div>
      {isShowModal && (
        <AddVehicleType
          confirmModal={isShowModal}
          setConfirmModal={setIsShowModal}
          rowData={rowData}
          modalType={modalType}
          setLoading={setLoading}
          xceleratorId={locationData?.id}
          getApiData={fetchFilteredData}
          apiData={apiData}
          apiType={apiType}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default VehicleType;
