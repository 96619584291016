import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { XCrossIcon } from "../../../assets";
import { Description } from "@mui/icons-material";
import { postApi, putApi } from "../../../apis/api";
import { RootState } from "../../../redux/store";
import { useMessage } from "../../../context/MessageContext";
import { GENERAL_ERROR_MESSAGE, ORDER_STATUS_MESSAGES, VEHICLE_TYPE_MESSAGES } from "../../../utils/message";



interface AddEditOrderStatusProps {
  confirmModal: boolean;
  setConfirmModal: (value: boolean) => void;
  rowData: any;
  modalType: string;
  setLoading: (value: boolean) => void;
  xceleratorId: string;
  getApiData: (currentPage: number) => void;
  apiData?: {
    agentId: string;
    agentHubId: string;
    orderDispatchCredId: string;
  };
  apiType?: {
    id: string;
    name: string;
  };
}
interface DtsStatusCodeType {
  id: string;
  name: string;
}
interface ColumnTypes {
  key: string;
  title: string;
}
interface FormValuesTypes {
  name: string;
  description: string;
  active: boolean;
}
interface GetOrderStatusApiResponse {
  xcStatusCode: string;
  isActive: boolean;
  statusCode: {
    id: string;
    name: string;
    description: string;
    ltl: boolean;
    whiteGlove: boolean;
    courier: boolean;
    onDemand: boolean;
    dts: boolean;
    customer: boolean;
    isActive: boolean;
  };
  xcelerator: {
    id: string;
    accountNo: string;
    isActive: boolean;
    useExpediteOrders: boolean;
  };
}

const DtsStatusCodeColumns: ColumnTypes[] = [
  { key: "name", title: "DTS Status Code" },
];

const mandatoryFields: (keyof FormValuesTypes)[] = ["name", "description"];

/**
 * AddEditOrderStatus Component
 *
 * This component is responsible for handling the addition and editing of order status.
 * It provides a form interface to input or edit order status codes, with validations
 * and checks to ensure necessary fields are filled out. The component also handles
 * API calls to fetch, create, and update order statuses.
 *
 * @param confirmModal - Boolean to control the visibility of the modal.
 * @param setConfirmModal - Function to set the visibility of the modal.
 * @param rowData - Data passed to the modal regarding the current order status.
 * @param modalType - String to indicate whether the modal is for adding or editing.
 * @param setLoading - Function to control the loading state.
 * @param xceleratorId - The ID of the xcelerator.
 * @param getApiData - Function to refresh the order status data after an action is performed.
 */
const AddVehicleType: React.FC<AddEditOrderStatusProps> = (props) => {
  const {
    confirmModal,
    setConfirmModal,
    rowData,
    modalType,
    setLoading,
    xceleratorId,
    getApiData,
    apiData,
    apiType,
  } = props;
  // Hook to set global messages
  const [dtsStatusCodeDropdown, setDtsStatusCodeDropdown] = useState<
    DtsStatusCodeType[]
  >([]);
  const { currentPage } = useSelector((state: RootState) => state.pagination);
  const { setMessage } = useMessage();
  const [dtsStatusCodeRowData, setDtsStatusCodeRowData] =
    useState<DtsStatusCodeType>({
      id: "",
      name: "",
    });
  const [formValues, setFormValues] = useState<FormValuesTypes>({
    name: "",
    description: "",
    active: true,
  });
  const [checkFormChanged, setCheckFormChanged] = useState<boolean>(false);
  // Hook for handling form change navigation
  // const {
  //   checkInputChange,
  //   handleNavigation,
  //   handleConfirmNavigation,
  //   handleCancelNavigation,
  //   handleUpdateNavigation,
  //   showModal,
  //   isFormChanged,
  // } = useFormChangeHandler();

  /**
   * Handles the closing of the modal. If the form has been changed, it prompts the user with a confirmation modal.
   */
  const handleClose = () => {
    // if (isFormChanged) {
    //   handleNavigation();
    // } else {
    setConfirmModal(false);
    // }
  };

  /**
   * Handles changes in the input fields and updates the form values state.
   * It also performs basic validation to prevent negative values in numeric fields.
   *
   * @param e - The input change event.
   */
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;
    // Prevent leading whitespace
    let newValue = value.trimStart();
    // //Prevent store negative values
    if (type === "number" && parseFloat(value) < 0) {
      return;
    }

    setFormValues((prev) => ({
      ...prev,
      [id]: type === "checkbox" ? checked : newValue,
    }));
    setCheckFormChanged(true);
    // checkInputChange();
  };


  const handleSave = async () => {
      try {
        setLoading(true);
        
        const apiPayload =
          modalType === "Edit"
            ? {
                name: formValues?.name,
                description: formValues.description,
                isActive: formValues.active ? true : false,
              }
            : {
                name: formValues?.name,
                description: formValues?.description,
                isActive: formValues.active ? true : false,
              };
  
        let ApiUrl;
        if (apiType?.id === "dispatchTrack") {
          ApiUrl =
            modalType === "Edit"
              ? putApi(
                  `/service/vehicletype/update/${rowData?.id}`,
                  apiPayload
                )
              : postApi(
                  `/service/vehicletype/create`,
                  apiPayload
                );
        } else {
          ApiUrl =
            modalType === "Edit"
              ? putApi(
                  `/service/vehicletype/update/${rowData?.id}`,
                  apiPayload
                )
              : postApi(`/service/vehicletype/create`,
                  apiPayload);
        }
  
        const apiResponse = await ApiUrl;
        if (apiResponse.success) {
          // handleUpdateNavigation();
          setConfirmModal(false);
          getApiData(currentPage);
          setMessage(
            modalType === "Edit"
              ? VEHICLE_TYPE_MESSAGES.SUCCESS_UPDATE
              : VEHICLE_TYPE_MESSAGES.SUCCESS_ADD,
            "success"
          );
        } else {
          setMessage(
            apiResponse.error?.message ?? GENERAL_ERROR_MESSAGE,
            "error"
          );
        }
      } catch (error) {
        setMessage(GENERAL_ERROR_MESSAGE, "error");
      } finally {
        setLoading(false);
      }
    };
  const isButtonDisabled = useMemo(() => {
    const areFieldsComplete =
      !mandatoryFields.some((field) => !formValues[field]) &&
      checkFormChanged;
    return !areFieldsComplete;
  }, [checkFormChanged, formValues]);

  /**
   * Handles the saving of order status data. Depending on the modal type, it either creates or updates the order status.
   */

  /**
   * Effect to fetch data when the modal is opened in edit mode.
   */
  useEffect(() => {
    console.log(rowData);
    if (confirmModal) {
      if (modalType === "Edit" && rowData) {
        setFormValues((prev) => ({
          ...prev,
          name: rowData?.name || "",
          description: rowData?.description || "",
          active: rowData?.active || false,
        }));
      }
    }
  }, [confirmModal]);

  return (
    <>
      <div className="modal modal--hazmatDetail modal--open">
        <div className="modal__dialog">
          <div className="modal__content modal__content--lg">
            <div className="modal__header">
              <h4 className="modal__header--title">
              {modalType === "Edit" ? "Edit Vehicle Type" : "Create Vehicle Type"}
              </h4>
              <button
                className="btn btn__transparent btnClose"
                onClick={handleClose}
              >
                <XCrossIcon />
              </button>
            </div>
            <div className="modal__body">
              <div className="formRow formRow__2">
              <div className="formCol">
                  <div className="form__Field">
                    <label htmlFor="xcStatusCode" className="form__label">
                      {" "}Vehicle Type
                      <span className="mandatory-mark">*</span>
                    </label>
                    <input
                      type="text"
                      id="dtStatusCode"
                      className={modalType === "Edit"?"form__input form__input--disabled form__input--sm":"form__input form__input--sm"}
                      placeholder="Vehicle Type"
                      value={formValues.name.trimStart()}
                      onChange={(e)=>{
                        setFormValues((prev) => ({
                          ...prev,
                          ["name"]: e.target.value,
                        }));
                        setCheckFormChanged(true);
                      }}
                      disabled = {modalType === "Edit"}
                      maxLength={200}
                    />
                  </div>
                </div>
                <div className="formCol">
                  <div className="form__Field">
                    <label htmlFor="xcStatusCode" className="form__label">
                      {" "}Description
                      <span className="mandatory-mark">*</span>
                    </label>
                    <input
                      type="text"
                      id="dtStatusCode"
                      className="form__input form__input--sm"
                      placeholder="Description"
                      value={formValues.description.trimStart()}
                      onChange={(e)=>{
                        setFormValues((prev) => ({
                          ...prev,
                          ["description"]: e.target.value,
                        }));
                        setCheckFormChanged(true);
                      }}
                      maxLength={200}
                    />
                  </div>
                </div>
              </div>
              {/* checkbox */}
              <div className="formRow">
                <div className="formCol">
                  <div className="form__Field">
                    <div className="form__switchBtn">
                      <div className="form__switchBtn__option">
                        <div className="form__label">Active</div>
                        <div className="switchBtn">
                          <input
                            type="checkbox"
                            className="toggle"
                            name="active"
                            id="active"
                            checked={formValues.active}
                            onChange={handleFieldChange}
                          />
                          <label htmlFor="active">
                            <span className="on">Yes</span>
                            <span className="off">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  disabled={isButtonDisabled}
                  onClick={handleSave}
                >
                  {modalType === "Edit" ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {/* {showModal && (
        <ConfirmModal
          confirmInfo={{
            heading: "Unsaved Changes!",
            para: "You have not saved your changes, do you want to continue",
          }}
          onConfirm={handleConfirmNavigation}
          setConfirmModal={handleCancelNavigation}
          firstMessageShow={true}
        />
      )} */}
    </>
  );
};

export default AddVehicleType;
