import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { DropdownMenu } from "../shared";
import { RootState } from "../../redux/store";
import useFetchLogo from "../../hooks/useFetchLogo";
import useFormChangeHandler from "../../hooks/useFormChangeHandler";
import { setGetPath } from "../../redux/reducer/prompt/promptSlice";
import { setActiveTab } from "../../redux/reducer/activeTab/activeTabSlice";

import {
  AccountingIcon,
  AgentManagementIcon,
  ApiSidebarIcon,
  ChangePasswordSidebarIcon,
  CustomerIcon,
  DashboardSidebarIcon,
  DoubleUserIcon,
  OrderManagementIcon,
  ProfileSidebarIcon,
  ServiceConfigIcon,
  SettingSidebarIcon,
} from "../../assets";
import { setLocationData } from "../../redux/reducer/locationSlice/locationSlice";

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userRole, isUserOfOmniMove, customerLevel, isCustomer } = useSelector(
    (state: RootState) => state.auth
  );
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const { handleNavigation, isFormChanged } = useFormChangeHandler();
  const [loading, setLoading] = useState<boolean>(false);
  // Custom hook to fetch logo URL from API
  const { logoUrl } = useFetchLogo({
    apiUrl: "/customer/logo",
    setLoading,
  });

  /**
   * Route configuration object defining the links and paths for different
   * management sections (e.g., user management, customer management, etc.).
   */
  const routeConfig = {
    userManagement: {
      links: [{ to: "/users", label: "Site Users", roles: ["*"] }],
      paths: [
        {
          parent: "/users",
          paths: ["/users", "/users/add", /^\/users\/edit\/\d+$/],
        },
      ],
    },
    customerManagement: {
      links: [
        {
          to: "/customers",
          label: "Customers",
          roles: isUserOfOmniMove
            ? ["superadmin", "admin", "manager"]
            : ["admin", "manager"] ||
              (customerLevel === "second" && ["admin", "manager"]) ||
              (customerLevel === "third" && ["customer", "employee"]),
        },
        {
          to: "/customer-users",
          label: "Customer Users",
          roles: isUserOfOmniMove ? [] : ["admin", "manager"],
        },
        // {
        //   to: "/customer/rate",
        //   label: "Customer Rates",
        //   roles:
        //     customerLevel === "first"
        //       ? ["admin", "manager", "employee"]
        //       : customerLevel === "second"
        //       ? []
        //       : ["admin", "manager", "employee"],
        // },
        // {
        //   to: "/customer-markup",
        //   label: "Customer Markup",
        //   roles: isUserOfOmniMove
        //     ? []
        //     : customerLevel === "second"
        //     ? [] // Hide for "second" level customers
        //     : customerLevel === "third"
        //     ? ["customer", "employee"]
        //     : ["admin", "manager"],
        // },
      ],
      paths: [
        {
          parent: "/customers",
          paths: [
            "/customers",
            "/customers/add",
            /^\/customers\/edit\/\d+$/,
            "/customer-address",
          ],
        },
        {
          parent: "/customer-users",
          paths: [
            "/customer-users",
            "/customer-users/add",
            /^\/customer-users\/edit\/\d+$/,
          ],
        },
        {
          parent: "/customers",
          paths: ["/customer/rate"],
        },
        // {
        //   parent: "/customer-markup",
        //   paths: [
        //     "/customer-markup",
        //     /^\/customer-markup\/edit\/\d+$/,
        //     "/customer-markup/add",
        //   ],
        // },
      ],
    },
    agentManagement: {
      links: [
        {
          to: "/agent-list",
          label: "My Agent List",
          roles: isUserOfOmniMove
            ? ["superadmin", "admin", "manager", "employee"]
            : ["admin", "manager", "employee"],
        },
        {
          to: "/master-agent-list",
          label: "Master Agent List",
          roles: isUserOfOmniMove
            ? ["superadmin", "admin", "manager", "employee"]
            : ["admin", "manager", "employee"] ||
              (customerLevel === "second" && []) ||
              (customerLevel === "third" && ["customer", "employee"]),
        },
      ],
      paths: [
        {
          parent: "/agent-list",
          paths: [
            "/agent-list",
            "/agent-list/add",
            /^\/agent-list\/edit\/\d+$/,
            /^\/agent-list\/\d+$/,
          ],
        },
        {
          parent: "/master-agent-list",
          paths: ["/master-agent-list", /^\/master-agent-list\/\d+$/],
        },
        {
          parent: "/agent-additional-charges",
          paths: ["/agent-additional-charges"],
        },
        {
          parent: "/agent-hubs",
          paths: [
            "/agent-hubs",
            "/agent-hubs/add",
            /^\/agent-hubs\/edit\/\d+$/,
          ],
        },
        {
          parent: "/agent-doc-list",
          paths: [
            "/agent-doc-list",
            "/agent-doc/add",
            /^\/agent-doc\/edit\/\d+$/,
          ],
        },
      ],
    },
    agentRate: {
      links: [
        {
          to: "/agent-mile-zone",
          label: "Agent Mile Zone",
          roles: ["superadmin", "admin", "manager"],
        },
        {
          to: "/agent-weight-zone",
          label: "Agent Weight Zone",
          roles: ["superadmin", "admin", "manager"],
        },
        {
          to: "/agent-rates",
          label: "Agent Rates",
          roles: ["superadmin", "admin", "manager"],
        },
        {
          to: "/fsc-agent-list",
          label: "FSC Agent List",
          roles: ["superadmin", "admin", "manager"],
        },
      ],
      paths: [
        {
          parent: "/agent-rates",
          paths: [
            "/agent-rates",
            "/agent-rates/add",
            /^\/agent-rates\/edit\/\d+$/,
          ],
        },
        {
          parent: "/agent-mile-zone",
          paths: [
            "/agent-mile-zone",
            "/agent-mile-zone/add",
            /^\/agent-mile-zone\/edit\/\d+$/,
          ],
        },
        {
          parent: "/agent-weight-zone",
          paths: [
            "/agent-weight-zone",
            "/agent-weight-zone/add",
            /^\/agent-weight-zone\/edit\/\d+$/,
          ],
        },
        {
          parent: "/fsc-agent-list",
          paths: [
            "/fsc-agent-list",
            "/fsc-agent-list/add",
            /^\/fsc-agent-list\/edit\/\d+$/,
          ],
        },
      ],
    },
    orderManagement: {
      links: [
        {
          to: "/save-quotation",
          label: "Saved Quotations",
          roles: isUserOfOmniMove
          ? []
          : customerLevel === "first" || customerLevel === "second"
          ? ["admin", "manager", "employee", "customer"]
          : [],
        },
        {
          to: "/customer-order-history",
          label: "Customer Order History",
          roles: isUserOfOmniMove
            ? ["agent"]
            : ["admin", "customer", "manager", "employee", "agent"],
        },
        {
          to: "/dispatch-board-list",
          label: "Dispatch Board",
          roles: isUserOfOmniMove
            ? []
            : ["admin", "customer", "manager", "employee"],
        },
        {
          to: "/billed-order-history",
          label: "Billed Order History",
          roles: isUserOfOmniMove
            ? ["superadmin", "admin", "manager", "employee"]
            : [],
        }
      ],
      paths: [
        {
          parent: "/customer-order-history",
          paths: [
            "/customer-order",
            "/customer-order-history",
            /^\/customer-order-history\/edit\/\d+$/,
          ],
        },
        {
          parent: "/dispatch-board-list",
          paths: ["/dispatch-board-list"],
        },
        {
          parent: "/billed-order-history",
          paths: [
            "/billed-order-history",
            /^\/billed-order-history\/view\/\d+$/,
          ],
        },
        {
          parent: "/save-quotation",
          paths: ["/save-quotation"],
        },
      ],
    },
    apiManagement: {
      links: [
        {
          to: "/Xcelarator-api",
          label: "Xcelarator API",
          roles: ["*"],
        },
      ],
      paths: [
        {
          parent: "/Xcelarator-api",
          paths: [
            "/Xcelarator-api",
            "/Xcelarator-api/add",
            /^\/Xcelarator-api\/edit\/\d+$/,
            /^\/Xcelarator-api\/agent\/\d+$/,
          ],
        },
      ],
    },
    accountManagement: {
      links: [
        {
          to: "/group-invoice-list",
          label: "Customer Invoice Groups",
          roles: ["superadmin", "admin", "manager", "employee"],
        },
        {
          to: "/invoice-list",
          label: "Order Invoice",
          roles: ["superadmin", "admin", "manager", "employee"],
        },
        {
          to: "/finalized-orders",
          label: "Finalized Orders",
          roles: ["superadmin", "admin", "manager", "employee"],
        },
      ],
      paths: [
        {
          parent: "/group-invoice-list",
          paths: [
            "/group-invoice-list",
            "/group-invoice-list/add",
            /^\/group-invoice-list\/edit\/\d+$/,
          ],
        },
        {
          parent: "/invoice-list",
          paths: [
            "/invoice-list",
            "/invoice-list/add",
            /^\/invoice-list\/edit\/\d+$/,
          ],
        },
        {
          parent: "/finalized-orders",
          paths: ["/finalized-orders"],
        },
      ],
    },
    agentApi: {
      links: [
        {
          to: "/agent-api",
          label: "Agent API",
          roles: isUserOfOmniMove
            ? []
            : customerLevel === "first"
            ? ["admin", "manager"]
            : [],
        },
      ],
      paths: [
        {
          parent: "/agent-api",
          paths: ["/agent-api", "/agent-api/add"],
        },
      ],
    },
    report: {
      links: [
        {
          to: "/create-report",
          label: "Create Report",
          roles: isUserOfOmniMove
            ? []
            : customerLevel === "first"
            ? ["admin", "manager", "employee"]
            : [],
        },
        {
          to: "/saved-reports",
          label: "Saved Reports",
          roles: isUserOfOmniMove
            ? []
            : customerLevel === "first"
            ? ["admin", "manager", "employee"]
            : [],
        },
      ],
      paths: [
        {
          parent: "/create-report",
          paths: ["/create-report"],
        },
        {
          parent: "/saved-reports",
          paths: ["/saved-reports"],
        },
      ],
    },
    setting: {
      links: [
        {
          to: "/settings-additional-charges",
          label: "Additional Charges",
          roles: ["superadmin", "admin", "manager"],
        },
      ],
      paths: [
        {
          parent: "/settings-additional-charges",
          paths: [
            "/settings-additional-charges",
            "/settings-additional-charges/add",
            /^\/settings-additional-charges\/edit\/\d+$/,
          ],
        },
      ],
    },
    systemConfiguration: {
      links: [
        {
          to: "/system-configuration/services",
          label: "Services",
          roles: isUserOfOmniMove
            ? []
            : customerLevel === "first"
            ? ["admin", "manager", "employee"]
            : [],
        },
        {
          to: "/system-configuration/vehicle",
          label: "Vehicle Type",
          roles: isUserOfOmniMove
            ? []
            : customerLevel === "first"
            ? ["admin", "manager", "employee"]
            : [],
        },
        {
          to: "/system-configuration/package-type",
          label: "Package Type",
          roles: isUserOfOmniMove
            ? []
            : customerLevel === "first"
            ? ["admin", "manager", "employee"]
            : [],
        },
      ],
      paths: [
        {
          parent: "/system-configuration/services",
          paths: ["/system-configuration/services"],
        },
        {
          parent: "/system-configuration/package-type",
          paths: ["/system-configuration/package-type"],
        },
        {
          parent: "/system-configuration/vehicle",
          paths: ["/system-configuration/vehicle"],
        },
      ],
    },
  };

  /**
   * Checks if the current location matches any path associated with a specific parent route.
   * This is used to determine if a link in the sidebar should be highlighted as active.
   *
   * @param parent - The parent route to check against the current location.
   * @returns True if the current path matches the parent route or its subpaths.
   */
  const isActiveLink = (parent: string): boolean => {
    return Object.values(routeConfig).some((config) =>
      config.paths.some(
        (p) =>
          p.parent === parent &&
          p.paths.some((path) =>
            typeof path === "string"
              ? location.pathname === path ||
                location.pathname.startsWith(path + "/")
              : path.test(location.pathname)
          )
      )
    );
  };

  /**
   * Checks if the specific path exactly matches the current location.
   *
   * @param path - The specific path to check.
   * @returns True if the current path matches the provided path.
   */
  const isSpecificLinkActive = (path: string): boolean => {
    return location.pathname === path;
  };

  /**
   * Closes the currently open dropdown menu.
   */
  const handleCloseDropdown = () => {
    setOpenDropdown(null);
  };

  /**
   * Toggles the state of a dropdown menu between open and closed.
   *
   * @param dropdown - The name of the dropdown menu to toggle.
   */
  const handleDropdownClick = (dropdown: string) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  /**
   * Handles navigation when a link is clicked, taking into unsaved form changes.
   *
   * If there are unsaved form changes, the user is prompted before navigating away.
   * Otherwise, the navigation proceeds as normal.
   *
   * @param path - The destination path to navigate to.
   * @param e - The click event that triggered the navigation.
   */
  const handleLinkNavigation = (
    path: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    dispatch(setLocationData(null));
    dispatch(setActiveTab(null));
    dispatch(setGetPath(path));
    if (isFormChanged) {
      e.preventDefault();
      handleNavigation(); // showing a confirmation dialog
    } else {
      navigate(path);
    }
  };

  /**
   * Determines the dropdown menu that should be open based on the current path.
   * Sets the state of the `openDropdown` to the corresponding dropdown.
   */
  useEffect(() => {
    const determineDropdown = () => {
      for (const [dropdown, config] of Object.entries(routeConfig)) {
        if (
          config.paths.some((p) =>
            p.paths.some((path) =>
              typeof path === "string"
                ? location.pathname === path ||
                  location.pathname.startsWith(path + "/")
                : path.test(location.pathname)
            )
          )
        ) {
          return dropdown;
        }
      }
      return null;
    };

    setOpenDropdown(determineDropdown());
  }, [location.pathname]);

  return (
    <>
      <div className="layoutSidemenu">
        <div className="layoutSidemenu__logo">
          <img
            onClick={(e) => {
              handleLinkNavigation("/", e);
            }}
            src={logoUrl}
            alt="Logo | Dickerson Transportation Solutions"
          />
        </div>
        <div className="layoutSidemenu__fullHeight">
          <ul className="layoutSidemenu__list">
            <li
              className={`layoutSidemenu__list--item ${
                isSpecificLinkActive("/") ? "layoutSidemenu__selected" : ""
              }`}
              onClick={() => {
                handleCloseDropdown();
              }}
            >
              <Link
                className="layoutSidemenu__list--link"
                onClick={(e) => {
                  handleLinkNavigation("/", e);
                }}
                to="/"
              >
                <span className="icon">
                  <DashboardSidebarIcon />
                </span>
                <span>Dashboard</span>
              </Link>
            </li>
            {userRole !== "employee" && userRole !== "agent" && (
              <DropdownMenu
                title="User Management"
                icon={<DoubleUserIcon />}
                links={routeConfig.userManagement.links}
                isActiveLink={(path) => isActiveLink(path)}
                isOpen={openDropdown === "userManagement"}
                onDropdownClick={() => handleDropdownClick("userManagement")}
                userIconClass={true}
                handleLinkNavigation={handleLinkNavigation}
              />
            )}
            <li
              className={`layoutSidemenu__list--item ${
                isSpecificLinkActive(
                  location.pathname === "/user/profile"
                    ? "/user/profile"
                    : "/email-notification-permissions"
                )
                  ? "layoutSidemenu__selected"
                  : ""
              }`}
              onClick={() => {
                handleCloseDropdown();
              }}
            >
              <Link
                className="layoutSidemenu__list--link"
                onClick={(e) => {
                  handleLinkNavigation("/user/profile", e);
                }}
                to="/user/profile"
              >
                <span className="icon">
                  <ProfileSidebarIcon />
                </span>
                <span>Profile</span>
              </Link>
            </li>
            <li
              className={`layoutSidemenu__list--item ${
                isSpecificLinkActive("/change-password")
                  ? "layoutSidemenu__selected"
                  : ""
              }`}
              onClick={() => {
                handleCloseDropdown();
              }}
            >
              <Link
                className="layoutSidemenu__list--link"
                onClick={(e) => {
                  handleLinkNavigation("/change-password", e);
                }}
                to="/change-password"
              >
                <span className="icon">
                  <ChangePasswordSidebarIcon />
                </span>
                <span>Change Password</span>
              </Link>
            </li>
            {userRole !== "employee" &&
              userRole !== "agent" &&
              userRole !== "customer" &&
              customerLevel !== "second" && (
                <DropdownMenu
                  title="Agent Management"
                  icon={<AgentManagementIcon />}
                  links={routeConfig.agentManagement.links}
                  isActiveLink={(path) => isActiveLink(path)}
                  isOpen={openDropdown === "agentManagement"}
                  onDropdownClick={() => handleDropdownClick("agentManagement")}
                  userIconClass={true}
                  userRole={userRole || ""}
                  handleLinkNavigation={handleLinkNavigation}
                />
              )}
            {userRole !== "employee" &&
              userRole !== "agent" &&
              userRole !== "customer" && (
                <DropdownMenu
                  title="Customer Management"
                  icon={<CustomerIcon />}
                  links={routeConfig.customerManagement.links}
                  isActiveLink={(path) => isActiveLink(path)}
                  isOpen={openDropdown === "customerManagement"}
                  onDropdownClick={() =>
                    handleDropdownClick("customerManagement")
                  }
                  userIconClass={false}
                  userRole={userRole || ""}
                  handleLinkNavigation={handleLinkNavigation}
                />
              )}
            <DropdownMenu
              title="Order Management"
              icon={<OrderManagementIcon />}
              links={routeConfig.orderManagement.links}
              isActiveLink={(path) => isActiveLink(path)}
              isOpen={openDropdown === "orderManagement"}
              onDropdownClick={() => handleDropdownClick("orderManagement")}
              userIconClass={false}
              userRole={userRole || ""}
              handleLinkNavigation={handleLinkNavigation}
            />
            {/* <DropdownMenu
              title="Api Management"
              icon={<ApiSidebarIcon />}
              links={routeConfig.apiManagement.links}
              isActiveLink={(path) => isActiveLink(path)}
              isOpen={openDropdown === "apiManagement"}
              onDropdownClick={() => handleDropdownClick("apiManagement")}
              userIconClass={true}
              userRole={userRole || ""}
              handleLinkNavigation={handleLinkNavigation}
            /> */}
            {customerLevel === "first" && userRole !== "agent" && (
              <DropdownMenu
                title="Accounting"
                icon={<AccountingIcon />}
                links={routeConfig.accountManagement.links}
                isActiveLink={(path) => isActiveLink(path)}
                isOpen={openDropdown === "accountManagement"}
                onDropdownClick={() => handleDropdownClick("accountManagement")}
                userIconClass={true}
                userRole={userRole || ""}
                handleLinkNavigation={handleLinkNavigation}
              />
            )}
            {/* {customerLevel === "first" && (
              <DropdownMenu
                title="Settings"
                icon={<SettingSidebarIcon />}
                links={routeConfig.setting.links}
                isActiveLink={(path) => isActiveLink(path)}
                isOpen={openDropdown === "setting"}
                onDropdownClick={() => handleDropdownClick("setting")}
                userIconClass={true}
                userRole={userRole || ""}
                handleLinkNavigation={handleLinkNavigation}
              />
            )} */}
            {customerLevel === "first" && userRole !== "agent" && (
              <DropdownMenu
                title="Report"
                icon={<AgentManagementIcon />}
                links={routeConfig.report.links}
                isActiveLink={(path) => isActiveLink(path)}
                isOpen={openDropdown === "report"}
                onDropdownClick={() => handleDropdownClick("report")}
                userIconClass={true}
                userRole={userRole || ""}
                handleLinkNavigation={handleLinkNavigation}
              />
            )}
            {customerLevel === "first" &&
              userRole !== "agent" &&
              userRole !== "employee" && (
                <DropdownMenu
                  title="API Management"
                  icon={<ApiSidebarIcon />}
                  links={routeConfig.agentApi.links}
                  isActiveLink={(path) => isActiveLink(path)}
                  isOpen={openDropdown === "agentApi"}
                  onDropdownClick={() => handleDropdownClick("agentApi")}
                  userIconClass={true}
                  userRole={userRole || ""}
                  handleLinkNavigation={handleLinkNavigation}
                />
              )}
            {customerLevel === "first" &&
              userRole !== "agent" &&
              userRole !== "employee" && userRole !== "manager" && (
                <DropdownMenu
                  title="System Configuration"
                  icon={<ServiceConfigIcon />}
                  links={routeConfig.systemConfiguration.links}
                  isActiveLink={(path) => isActiveLink(path)}
                  isOpen={openDropdown === "systemConfiguration"}
                  onDropdownClick={() =>
                    handleDropdownClick("systemConfiguration")
                  }
                  userIconClass={true}
                  userRole={userRole || ""}
                  handleLinkNavigation={handleLinkNavigation}
                />
              )}
          </ul>
        </div>
        <div className="layoutSidemenu__version">
          <h4 className="layoutSidemenu__version__title">Version 1.0.0</h4>
        </div>
      </div>
    </>
  );
};

export default SideBar;
