import httpService from "./axios-config/http-service";
import { clearTokenFromLocalStorage } from "../utils/authUtils";
import { GENERAL_ERROR_MESSAGE } from "../utils/message";

interface ApiResponse<T> {
  success: boolean;
  data?: T;
  error?: {
    message: string;
  };
  pagination?: Pagination;
}
interface Pagination {
  currentPage: number;
  totalPages: number;
  previousPage: number | null;
  nextPage: number | null;
  totalItems: number;
}
interface GetApiResponse<T> {
  success: boolean;
  data?: T;
  error?: {
    message: string;
  };
  pagination?: Pagination;
}

// Generic function to perform GET requests
export const getApi = async <T>(url: string): Promise<GetApiResponse<T>> => {
  try {
    const response = await httpService.get(url);

    if (response.success) {
      return {
        success: true,
        data: response.data,
        pagination: response.pagination,
      };
    } else {
      return {
        success: false,
        error: response.error,
      };
    }
  } catch (error: any) {
    console.error(`GET request failed:`, error);
    if (error.response && error.response.status === 423) {
      clearTokenFromLocalStorage();
      window.location.reload();
    }
    return {
      success: false,
      error: {
        message: GENERAL_ERROR_MESSAGE,
      },
    };
  }
};

// Generic function to perform POST requests
export const postApi = async <T>(
  url: string,
  formData: any
): Promise<ApiResponse<T>> => {
  try {
    const response = await httpService.post(url, formData);

    if (response.success) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return {
        success: false,
        error: response.error,
      };
    }
  } catch (error: any) {
    console.error(`POST request failed:`, error);

    return {
      success: false,
      error: {
        message: GENERAL_ERROR_MESSAGE,
      },
    };
  }
};

// Generic function to perform PUT requests
export const putApi = async <T>(
  url: string,
  formData: any
): Promise<ApiResponse<T>> => {
  try {
    const response = await httpService.put(url, formData);

    if (response.success) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return {
        success: false,
        error: response.error,
      };
    }
  } catch (error: any) {
    console.error(`Put request failed:`, error);

    return {
      success: false,
      error: {
        message: GENERAL_ERROR_MESSAGE,
      },
    };
  }
};

// Generic function to perform DELETE requests
export const deleteApi = async <T>(url: string): Promise<ApiResponse<T>> => {
  try {
    const response = await httpService.delete(url);

    if (response.success) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return {
        success: false,
        error: response.error,
      };
    }
  } catch (error: any) {
    console.error(`DELETE request failed:`, error);

    return {
      success: false,
      error: {
        message: GENERAL_ERROR_MESSAGE,
      },
    };
  }
};

// Generic function to perform DELETE requests
export const deletePayloadApi = async <T>(
  url: string,
  payload: any
): Promise<ApiResponse<T>> => {
  try {
    const response = await httpService.delete(url, payload);

    if (response.success) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return {
        success: false,
        error: response.error,
      };
    }
  } catch (error: any) {
    console.error(`DELETE request failed:`, error);

    return {
      success: false,
      error: {
        message: GENERAL_ERROR_MESSAGE,
      },
    };
  }
};

// Generic function to perform put requests
export const activePayloadApi = async <T>(
  url: string,
  payload: any
): Promise<ApiResponse<T>> => {
  try {
    const response = await httpService.put(url, payload);

    if (response.success) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return {
        success: false,
        error: response.error,
      };
    }
  } catch (error: any) {
    console.error(`Put request failed:`, error);

    return {
      success: false,
      error: {
        message: GENERAL_ERROR_MESSAGE,
      },
    };
  }
};
