import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import GetSavedQuote from "../../../components/getQuote/GetSavedQuote";
import { Loader } from "../../../components/loaders/loaders";
import { postApi, getApi, deletePayloadApi } from "../../../apis/api";
import { AddIcon, DeleteIcon } from "../../../assets";
import { useMessage } from "../../../context/MessageContext";
import { GENERAL_ERROR_MESSAGE } from "../../../utils/message";
import SearchInput from "../../../components/shared/SearchInput";
import { RightArrowSolid } from "../../../assets";
import { GET_QUOTATION, DELETE_QUOTATION } from "../../../apis/end-points";
import { expiredData } from "../../../utils/utils";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { RootState } from "../../../redux/store";
import {
  setPage,
  setTotalItems,
  setTotalPages,
  setLimit,
} from "../../../redux/reducer/pagination/paginationSlice";

const limit = 15;

// Define the columns to be used in the ViewDetailsTable
const columns = [
  { header: "S.", key: "serial", className: "count", count: true },
  {
    header: "Quote Id",
    key: "quoteId",
    sortable: true,
  },
  {
    header: "Service Name",
    key: "serviceName",
    sortable: true,
  },
  { header: "Quote Price", key: "quotePrice", dollar: true },
  { header: "Quote Status", key: "quoteStatus", className: "expired" },
];

const LTLColumns = [
  { header: "S.", key: "serial", className: "count", count: true },
  {
    header: "Quote Id",
    key: "quoteId",
    sortable: true,
  },
  {
    header: "Carrier Name",
    key: "carrierName",
    sortable: true,
  },
  { header: "Carrier Code", key: "carrierCode" },
  { header: "Estimated LTL Transit Days", key: "estimatedLTLTransitDay" },
  { header: "Quote Price", key: "quotePrice", dollar: true },
];

const LTLFinalMileColumns = [
  { header: "S.", key: "serial", className: "count", count: true },
  {
    header: "Quote Id",
    key: "quoteId",
    sortable: true,
  },
  {
    header: "Carrier Name (LTL)",
    key: "carrierName",
    sortable: true,
  },
  {
    header: "Agent (Final Mile)",
    key: "finalmileCarrierName",
    sortable: true,
  },
  { header: "Carrier Code", key: "carrierCode" },
  { header: "Estimated LTL Transit Days", key: "estimatedLTLTransitDay" },
  { header: "Quote Price", key: "quotePrice", dollar: true, sortable: true, },
];

const SavedQuotation: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPage = useSelector(
      (state: RootState) => state.pagination.currentPage
    );
  const { setMessage } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [totalQuotation, setTotalQuotation] = useState([]);
  const [savedQuotation, setSavedQuotation] = useState([]);
  const [keyword, setKeyword] = useState<string>("");
  const [showDeleteConfModal, setShowDeleteConfModal] =
    useState<boolean>(false);
  const [selectedQuoteId, setSelectedQuoteId] = useState<string[]>([]);
  const [rotatedArrow, setRotatedArrow] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  const toggleArrowRotation = (id: string) => {
    setRotatedArrow((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Use useCallback for debouncing the API call
  const handleKeywordChange = useCallback(
    _.debounce((inputText: string) => {
      getSavedQuotation(inputText);
    }, 2000), // 500ms delay
    [] // Empty dependency array ensures the debounced function is stable
  );

  const getSavedQuotation = async (searchKeyword = keyword) => {
    try {
      setLoading(true);
      // Assuming there's an endpoint for fetching record data with pagination
      const response = await getApi(
        `${GET_QUOTATION}?keyword=${searchKeyword}`
      );
      if (response.success && response.data) {
        const mapQuotationData = (userData: any) =>
          userData.map((user: any) => ({
            id: user?.quoteGroupById,
            page: "Save Quotation",
            customerName: user?.quotes[0]?.customer,
            orderType: user?.quotes[0]?.orderType?.name,
            orderDetails: user?.quotes[0]?.orderType,
            serviceName: user?.quotes[0]?.service?.name,
            serviceDetails: user?.quotes[0]?.service,
            destinationZipCode: user?.quotes[0]?.destinationAddress?.zipCode,
            originZipCode: user?.quotes[0]?.originAddress?.zipCode,
            destinationDetails: user?.quotes[0]?.destinationAddress,
            totalWeight: user?.quotes[0]?.destinationAddress?.weight,
            totalPieces: user?.quotes[0]?.destinationAddress?.pieces,
            quoteDetails: user?.quotes?.map((item: any, index: number) => ({
              serial: index + 1,
              quoteId: item?.id,
              quoteGroupById: user?.quoteGroupById,
              serviceName: item?.service?.name,
              description: item?.service?.description || "",
              quotePrice: item?.rateDetails?.total,
              quoteStatus:
                item?.isExpiry === 1 || !expiredData(item?.createdAt)
                ? "expired" : "",
              priceDetails: item?.rateDetails,
              agentName: item?.agent?.name,
              fuelSurcharge: item?.rateDetails?.fuelSurcharge,
              additionalOtherCharges: item?.rateDetails?.additionalOtherCharges,
              basePrice: item?.rateDetails?.basePrice,
              carrierName: item?.rateDetails?.carrierName,
              carrierCode: item?.rateDetails?.carrierCode,
              estimatedLTLTransitDay: item?.rateDetails?.estimatedLTLTransitDay,
              finalmileCarrierName: item?.rateDetails?.finalmileCarrierName,
            })),
          }));
        const qData = mapQuotationData(response.data);
        const indexOfLastItem = currentPage * limit;
        const indexOfFirstItem = indexOfLastItem - limit;
        
        // Slice the data based on the current page
        const currentData = qData.slice(indexOfFirstItem, indexOfLastItem);  
        setTotalQuotation(qData);
        setSavedQuotation(currentData);
        /****************** Set Pagination Start ******************************/
        const paginationData = {
          totalItems: qData.length || 0,
          totalPages: Math.ceil(qData.length / limit) || 1,
          currentPage: currentPage || 1,
        };
        paginationData.currentPage =
          paginationData?.currentPage > paginationData?.totalPages
            ? paginationData?.totalPages
            : paginationData?.currentPage;
        dispatch(setTotalItems(paginationData.totalItems));
        dispatch(setTotalPages(paginationData.totalPages));
        dispatch(setLimit(limit));
        /****************** Set Pagination End ******************************/
      } else {
        setMessage(response?.error?.message ?? GENERAL_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      console.log("error", error);
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSavedQuotation();
  }, []);

  useEffect(()=>{
    const indexOfLastItem = currentPage * limit;
    const indexOfFirstItem = indexOfLastItem - limit;
    
    // Slice the data based on the current page
    const currentData = totalQuotation.slice(indexOfFirstItem, indexOfLastItem);  
    setSavedQuotation(currentData);
    setCheckedIds([]); setSelectAllChecked(false)
  }, [currentPage])

  /**
   * Handles checkbox change for a row.
   * When checked, sets isModified to true, when unchecked, sets it to false.
   * @param rowIndex - Index of the row being modified.
   */
  const handleCheckboxChange = (rowId: string) => {
    if (rowId === "All") {
      setSelectAllChecked(!selectAllChecked);
      if (selectAllChecked) {
        setCheckedIds([]);
      } else {
        const ids = savedQuotation.map((item: any) => item.id);
        setCheckedIds(ids);
      }
    } else {
      const newCheckedIds = checkedIds.includes(rowId)
            ? checkedIds.filter((item: string) => item !== rowId) // Remove item
            : [...checkedIds, rowId];

      setCheckedIds &&
        setCheckedIds(newCheckedIds);
      if (
        savedQuotation.length === newCheckedIds.length &&
        !selectAllChecked
      ) {
        setSelectAllChecked(true);
      } else if (
        savedQuotation.length !== [...checkedIds, rowId].length &&
        selectAllChecked
      ) {
        setSelectAllChecked(false);
      }
    }
  };

  const deleteQuotation = async () => {
    try {
      const apiPayload = {
        quoteGroupByIdArr: selectedQuoteId,
      };
      setLoading(true);
      const apiResponse = await deletePayloadApi(DELETE_QUOTATION, {
        data: apiPayload,
      });
      if (apiResponse.success) {
        getSavedQuotation();
        setCheckedIds([]);
        setMessage("Quotation Deleted Successfully!", "success");
        setShowDeleteConfModal(false);
      } else {
        setMessage(
          apiResponse.error?.message ?? GENERAL_ERROR_MESSAGE,
          "error"
        );
      }
    } catch (error) {
      console.error(GENERAL_ERROR_MESSAGE, error);
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = e.target.value;
    setKeyword(inputText); // Update local state
    handleKeywordChange(inputText); // Call the debounced function
  };

  const goToViewQuote = (quoteData: any) => {
    navigate("/customer-order", {
      state: { page: "Save Quotation", id: quoteData?.id },
    });
  };

  const handleSelect = (quoteData: any) => {
    navigate("/customer-order", {
      state: {
        page: "Save Quotation",
        id: quoteData?.id,
        isQuoteSelected: true,
        quoteId: quoteData?.quoteId,
      },
    });
  };

  const showPopupOnDelete = (quoteId: any) => {
    setSelectedQuoteId(quoteId);
    setShowDeleteConfModal(true);
  };

  return (
    <>
      <div className="details">
        <div className="form form--innersec">
          <section className="form__section">
            <div className="form__section__title form__section__title--flex">
              <div className="formCol" style={{width:"30%"}}>
                <SearchInput
                  type="text"
                  value={keyword}
                  placeholder="Search by Quote Id, Zip Code"
                  onChange={handleChange}
                />
              </div>
              {savedQuotation?.length ? (
                <div className="btn__group">
                  <button
                    className="btn btn__danger btn__outlined btn--medium"
                    disabled={checkedIds?.length === 0}
                    onClick={() => showPopupOnDelete(checkedIds)}
                  >
                    <DeleteIcon />
                    Delete All
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            {savedQuotation?.length ? (
              <div className="form__checkbox form__checkbox--gray">
                <div
                  className="form__checkbox__option mb_0 mr_0"
                  style={{ padding: "0px" }}
                >
                  <input
                    type="checkbox"
                    id="poison"
                    className={`form__checkbox__input`}
                    checked={selectAllChecked}
                    onChange={() => handleCheckboxChange("All")}
                  />
                  <label
                    className="form__checkbox__label pl_18"
                    htmlFor="poison"
                  >
                    &nbsp; Select All
                  </label>
                </div>
              </div>
            ) : (
              <div className="form__section__title form__section__title--center">
                No Results Found!
              </div>
            )}
            {savedQuotation.map((item: any, index) => (
              <div className="formRow formRow--gapNone" key={index}>
                <div className="form__section__title form__section__title--flex">
                  <div className="form__section__title form__section__title--flex form__section__title--flexStart mb_0">
                    <div className="form__checkbox form__checkbox--gray">
                      <div
                        className="form__checkbox__option mb_0 mr_0"
                        style={{ padding: "0px" }}
                      >
                        <input
                          type="checkbox"
                          id="poison"
                          className={`form__checkbox__input`}
                          checked={checkedIds.includes(item?.id)}
                          onChange={() => handleCheckboxChange(item?.id)}
                        />
                        <label
                          className="form__checkbox__label pl_18"
                          htmlFor="poison"
                        >
                          &nbsp;
                        </label>
                      </div>
                    </div>
                    <span
                      style={{
                        display: "inline-block",
                        transform: rotatedArrow[item?.id]
                          ? "rotate(90deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.1s ease",
                      }}
                      onClick={() => {
                        toggleArrowRotation(item?.id);
                      }}
                    >
                      <RightArrowSolid />
                    </span>
                    <a
                      href="javascript:void(0)"
                      className="form__section__title form__section__title--second mb_0"
                      onClick={() => {
                        goToViewQuote(item);
                      }}
                    >
                      {item?.orderType === "Final Mile"
                        ? `Customer Name: ${item?.customerName?.name} | Order Type: ${item?.orderType} |
                    Service Name: ${item?.serviceName} | Destination Zip Code: ${item?.destinationZipCode} | Total Weight:
                    ${item?.totalWeight} | Total Pieces: ${item?.totalPieces}`
                        : item?.orderType === "LTL"
                        ? `Customer Name: ${item?.customerName?.name} | Order Type: ${item?.orderType} |
                    Service Name:${item?.serviceName} | Origin Zip Code: ${item?.originZipCode} | Destination Zip Code: ${item?.destinationZipCode} | Total Weight:
                    ${item?.totalWeight} | Total Pieces: ${item?.totalPieces}`
                        : `Customer Name: ${item?.customerName?.name} | Order Type: ${item?.orderType} |
                    Service Name: ${item?.serviceName} | Origin Zip Code: ${item?.originZipCode} | Destination Zip Code: ${item?.destinationZipCode} | Total Weight:
                    ${item?.totalWeight} | Total Pieces: ${item?.totalPieces}`}
                    </a>
                  </div>
                  <div className="btn__group mb_0">
                    <button
                      className="btn btn__danger btn__outlined btn--medium"
                      onClick={() => showPopupOnDelete([item?.id])}
                    >
                      Delete Quotation
                    </button>
                  </div>
                </div>
                {rotatedArrow[item?.id] === true && (
                  <div className="tableWrap tableWrap--order pt_0">
                    <div className="tableContainer">
                      <GetSavedQuote
                        columns={
                          item?.orderType === "LTL"
                            ? LTLColumns
                            : item?.orderType === "LTL + Final Mile"
                            ? LTLFinalMileColumns
                            : columns
                        }
                        data={item?.quoteDetails}
                        orderType={item?.orderType}
                        handleSelect={handleSelect}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </section>
        </div>
      </div>
      {showDeleteConfModal && (
        <ConfirmModal
          confirmInfo={{
            heading: "Delete Quotation!",
            para: "Do you want to delete the selected saved quotation",
          }}
          onConfirm={deleteQuotation}
          setConfirmModal={() => setShowDeleteConfModal(false)}
          firstMessageShow={true}
        />
      )}
    </>
  );
};

export default SavedQuotation;
