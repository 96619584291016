export interface ApiResponse {
  id: string;
  count: number | string;
  name: string;
  email: string;
  phoneNumber: string;
  contactPerson: string;
  isActive: boolean;
  active: boolean;
  isSignedUp: boolean;
  countryCallingCode: string;
  logo: string | null;
  agent: {
    name: string;
    agentNumber: string;
    id: string;
  };
  agentHub: {
    name: string;
  };
  startDate: string;
  apiType: string;
  clientCode: string;
  clientAPI: string;
}

export const xcelaratorColumns = [
  { key: "count", header: "S.", isSortable: false, className: "count" },
  {
    key: "agentName",
    header: "Agent Name",
    isSortable: true,
    className: "profileLink",
  },
  {
    key: "agentNumber",
    header: "Agent Number",
    isSortable: true,
    className: "dark",
  },
  {
    key: "hubName",
    header: "Hub Name",
    isSortable: true,
    className: "dark",
    upperCase: true,
  },
  {
    key: "startDate",
    header: "Start Date",
    isSortable: true,
    className: "dark",
  },
  {
    key: "active",
    header: "Active",
    isSortable: false,
    isActive: true,
    handleActive: true,
    className: "dark",
  },
];

export const xcelaratorTableConfig = {
  edit: {
    apiUrl: "/agent-api/edit/",
    urlID: "id",
    stateData: false,
  },
  delete: {
    apiUrl: "/agent/clientCredential/deleteAgentCred/",
    urlID: "id",
    successMessage: "Agent API deleted successfully!",
    heading: "Delete Agent API!",
    para: "delete",
    rowName: "agentName",
    secondRowName: "",
  },
  active: {
    apiUrl: "/agent/xcelerator/",
    urlID: "id",
    successMessage: "Xcelerater API",
    heading: "Xcelerater API!",
    para: "",
    rowName: "agentName",
    secondRowName: "",
    onboardedMessage: "Customer",
  },
  profileLink: {
    apiUrl: "/agent-api/edit/",
    urlID: "id",
  },
};

export interface AddXAPIField {
  agentName: {
    id: string;
    name: string;
  };
  hubName: {
    id: string;
    name: string;
  };
  apiUrl: string;
  apiAccountNo: string;
  apiLogin: string;
  apiPassword: string;
  startDate: string;
  active: boolean;
}

export interface AgentData {
  id: string;
  name: string;
}

export interface AgentListApiResponse {
  data: AgentData[];
  success: boolean;
}

export interface XcelaratorApiType {
  agentId?: string;
}
