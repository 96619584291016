// Configuration for the table actions (edit, delete, activate)

export const ServiceTypeTableConfig = {
  columns: [
    { key: "count", header: "S.", isSortable: false, className: "count" },
    {
      key: "serviceName",
      header: "Service Name",
      isSortable: true,
      className: "dark",
    },
    {
      key: "description",
      header: "Description",
      isSortable: true,
      className: "dark",
    },
    {
      key: "shortName",
      header: "Short Name",
      isSortable: true,
      className: "dark",
    },
    {
      key: "agentType",
      header: "Agent Type",
      isSortable: true,
      className: "dark",
    },
    {
      key: "deliverWithin",
      header: "Deliver Within",
      isSortable: true,
      className: "dark",
    },
    {
      key: "comment",
      header: "Comments",
      isSortable: true,
      className: "dark",
    },
    {
      key: "dispatchColor",
      header: "Dispatch Color",
      isSortable: false,
      className: "dark",
      //   isActive: true,
      colorPallete: true,
    },
    {
      key: "active",
      header: "Active",
      isSortable: false,
      isActive: true,
      className: "dark",
    },
  ],
  edit: {
    apiUrl: "/service/updateService/",
    urlID: "id",
    stateData: false,
  },
  delete: {
    apiUrl: "service/deleteService/",
    urlID: "id",
    successMessage: "Service deleted successfully!",
    heading: "Delete Service!",
    para: "delete",
    rowName: "serviceName",
    secondRowName: "",
    deleteByPutApi: true,
  },
  active: {
    apiUrl: "",
    urlID: "id",
    successMessage: "Additional Charges",
    heading: "Additional Charges!",
    para: "",
    rowName: "additionalCharges",
    secondRowName: "",
    onboardedMessage: "Additional Charges",
  },
};

export interface ServiceTypeResponseType {
  id: string;
  count: string | number;
  name: string;
  vehicleType: string;
  shortName: string;
  longName: string;
  deliverWithin: string;
  dispatchTicketColor: string;
  dispatchPrinter: string;
  agentType: any;
  agentTypeId: any;
  useOver150Lbs: boolean;
  description: string;
  serviceDays: string[];
  minsNotReceiveMsg: string;
  minsNotReadMsg: string;
  zoneSet: string;
  delSignRequired: boolean;
  printDispatchTrack: boolean;
  orderDispatch: boolean;
  notReceivedMsgAlert: boolean;
  notReadMsgAlert: boolean;
  skipPickupStops: boolean;
  skipDelRoundStops: boolean;
  availCust: boolean;
  discInvoiceLevel: boolean;
  comment: string;
  canCustomerUse: boolean;
  disInvoiceLevel: boolean;
  isActive: boolean;
}
