import React from "react";
import { ArrowDownLightIcon } from "../../../assets";

interface Column {
  header: string;
  key: string;
  count?: boolean;
  sortable?: boolean;
  className: string;
  dollar?: boolean;
}

interface Data {
  [key: string]: any;
}

interface ViewDetailsTableProps {
  columns: Column[];
  data: Data[];
  handleViewDetails: (rowData: Data) => void;
  handleSelect: (rowData: Data) => void;
  className?: string;
}

const ViewDetailsTable: React.FC<ViewDetailsTableProps> = (props) => {
  const { columns, data, handleViewDetails, handleSelect, className } = props;
  return (
    <>
      <div className="tableContainer">
        <table className={`table ${className}`}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>
                  {column.count ? (
                    column.header
                  ) : (
                    <div className="sortingDiv">
                      {column.header}
                      {column.sortable && (
                        <span className="sortingDiv__icon">
                          <ArrowDownLightIcon />
                        </span>
                      )}
                    </div>
                  )}
                </th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data?.length === 0 ? (
              <tr>
                <td colSpan={columns?.length + 1}>
                  <div className="no-data floating">No Results Found!</div>
                </td>
              </tr>
            ) : (
              data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((column, colIndex) => (
                    <td key={colIndex}>
                      {column?.className === "count" ? (
                        <div className="cellText">
                          <span className="cellText__dark cellText__dark--gray">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : (
                        <div className="cellText">
                          <span className="cellText__dark">
                            {column?.dollar
                              ? `$${row[column.key].toFixed(2)}`
                              : row[column.key]}
                          </span>
                        </div>
                      )}
                    </td>
                  ))}
                  <td>
                    <div className="actions flex--center">
                      <button
                        className="link link__primary link--bold"
                        onClick={() => handleViewDetails(row)}
                      >
                        View
                      </button>
                      <button
                        className="btn btn__primary btn__outlined btn--medium"
                        onClick={() => handleSelect(row)}
                      >
                        Select
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ViewDetailsTable;
